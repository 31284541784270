import React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Callout from "../../../components/callout"
import Modal from "../../../components/modal"
import Tabs from "../../../components/template-partials/global-components/tabs/tabs"
import HangingRef from "../../../components/hanging-ref"

import ImageBmiZscoreReduction from "../../../images/bmi_z_score-no-p-value.png"
import ImageBmiIndividualResults from "../../../images/bmi-individual-results.png"
import ImageChangeInBmiPercentile from "../../../images/change-in-bmi-percentile-hcp-bbs.png"

const PediatricBmiZscore = () => {
  return (
    <Layout>
      <Seo
        title="Pediatric Weight Reduction | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="Learn about weight reduction in patients under the age of 18 with BBS. Please see full Prescribing Information for Important Safety Information."
        ogTitle="Pediatric Clinical Study Weight Results | IMCIVREE® (setmelanotide) injection"
        ogDesc="Review the clinical study weight results of IMCIVREE® (setmelanotide) in patients under the age of 18. Please see full Prescribing Information for Important Safety Information."
      />
      <div className="content-block" id="pediatric-bmi-z-score-reduction-hcp">
        <div
          className="h2--no-margin-bottom lg_mt-1"
          style={{ lineHeight: "2.5rem" }}
        >
          In patients 6 to &lt;18 years of age with BBS
        </div>
        <h1 className="h1">
          IMCIVREE delivered early, significant, and sustained weight reduction
          <sup>1-3</sup>
        </h1>
        <div>&nbsp;</div>
        <div className="row">
          <div className="columns">
            <Tabs className="--hcp">
              <Tabs.Pane name="BMI Z-SCORE REDUCTION OVER TIME" key="1">
                <div className="row">
                  <div className="column small-12 large-9">
                    <h2 className="h2 text-center">
                      BMI Z-score* reduction in patients  6 to &lt;18 years of age
                      <sup>1-3&dagger;</sup>
                    </h2>
                    <img
                      src={ImageBmiZscoreReduction}
                      alt="Change in BMI Z-score chart"
                      className="md_pr-1"
                    />
                  </div>
                  <div className="column small-12 large-3 md_pt-6 pt-2 pb-2">
                    <Modal id="bmi-14-week-reduction-1" />
                    <p className="md_mb-1"></p>
                    <Modal id="bmi-24-month-long-term-1" />
                  </div>
                </div>
                <div className="show-for-large">&nbsp;</div>
                <div
                  id="bmi-z-score-reduction-over-time"
                  className="md_mt-1 mb-2 md_mb-2"
                >
                  <Callout
                    left
                    className="light-teal left text-left"
                    style={{ paddingLeft: `10%` }}
                  >
                    <p className="mb-1 md_mb-1">
                      Clinically significant -0.8 mean change in BMI Z-score at
                      week 52<sup>3,4</sup>
                    </p>
                    <ul
                      className="ul--standard neon-green-bullets"
                      style={{ paddingLeft: `25px` }}
                    >
                      <li
                        className="jost-semibold no-marker"
                        style={{ lineHeight: "1.1em" }}
                      >
                        Sustained mean reductions in BMI Z-score at
                        24&nbsp;months in a long-term extension&nbsp;study
                        <sup>5</sup>
                      </li>
                    </ul>
                  </Callout>
                </div>
                <div className="row col-reverse">
                  <div className="column small-12">
                    <HangingRef
                      mb0
                      symbol="*"
                      copy={
                        <>
                          BMI Z-score was an exploratory endpoint used to
                          measure the reduction in BMI in children. A BMI
                          Z-score is a reliable measure of weight in children
                          who are still growing because it accounts for height,
                          age, and gender.<sup>6,7</sup>
                        </>
                      }
                    />
                    <HangingRef
                      mb0
                      symbol="&dagger;"
                      copy={
                        <>
                          Data shown include only patients who received 52 weeks
                          of IMCIVREE at the time of the analysis. Population
                          sizes ranged from 8 to 16, with n=14 at 52 weeks on
                          active treatment. Error bars are the standard
                          deviation.<sup>2</sup>
                        </>
                      }
                    />
                    <HangingRef
                      mb0
                      symbol="&Dagger;"
                      copy={
                        <>
                          A clinically significant reduction is generally
                          considered a &ge;0.2 reduction in BMI Z-score. A 0.2
                          reduction is comparable to weight loss of
                          approximately 5%.<sup>3</sup>
                        </>
                      }
                    />
                    <HangingRef
                      mb0
                      symbol="&sect;"
                      copy={
                        <>
                          ATB=active treatment baseline, defined as the last
                          measurement before the first dose of IMCIVREE; ie,
                          week 0 for IMCIVREE group and week 14 for placebo
                          group.<sup>2</sup>
                        </>
                      }
                    />
                  </div>
                </div>
                <div>&nbsp;</div>
              </Tabs.Pane>
              <Tabs.Pane name="INDIVIDUAL RESULTS" key="2">
                <h2 className="h2 text-center">
                  Change in BMI Z-score from baseline in patients 6 to &lt;18
                  years of age after 52 weeks (n=14)<sup>1</sup>
                </h2>
                <div className="row align-justify mb-2 xl_mb-2">
                  <div className="column small-12 xlarge-8">
                    <div className="text-center chart-overflow-x pb-2 swipe-container">
                      <img
                        src={ImageBmiIndividualResults}
                        alt="Individual Change in BMI Z-score"
                      />
                    </div>
                  </div>
                  <div className="hide-for-xlarge">&nbsp;</div>
                  <div className="column small-12 xlarge-3 container-86-percent">
                    <p
                      className="color-neon-green"
                      style={{
                        fontSize: "400%",
                        fontWeight: "600",
                        lineHeight: "3rem",
                      }}
                    >
                      86%
                    </p>
                    <p className="mb-0">
                      of patients achieved a clinically significant &ge;0.2
                      reduction* in BMI{" "}
                      <span className="nowrap">
                        Z-score<sup>1,3</sup>
                      </span>
                    </p>
                    <ul className="neon-green-bullets">
                      <li>
                        100% of patients &lt;12 years of age achieved a
                        clinically significant &ge;0.2 reduction in BMI Z-score
                        (n=3)<sup>1,3</sup>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="show-for-large">&nbsp;</div>
                <div className="row">
                  <div className="columns small-12">
                    <div className="row mt-0">
                      <div className="columns small-12 large-5">
                        <Modal id="bmi-14-week-reduction-2" />
                      </div>
                      <div className="columns large-1 show-for-large">
                        &nbsp;
                      </div>
                      <div className="hide-for-large">&nbsp;</div>
                      <div className="columns small-12 large-5">
                        <Modal id="bmi-24-month-long-term-2" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex small-12 mt-1 md_mt-2"
                    style={{ alignItems: "center" }}
                  >
                    <div style={{ minWidth: "50%", flex: 1 }}>
                      <HangingRef
                        mb0
                        symbol="*"
                        isAsterisk
                        copy={
                          <>
                            BMI Z-score was an exploratory endpoint used to
                            measure the reduction in BMI in children. A
                            clinically significant reduction is generally
                            considered a ≥0.2 reduction in BMI Z-score. A 0.2
                            reduction is comparable to weight loss of
                            approximately 5%.
                            <sup>3</sup>
                          </>
                        }
                      />
                    </div>
                    <Callout
                      right
                      className="light-teal right text-left show-for-large"
                    >
                      <p>
                        Patients were not required to change their diet or
                        exercise routine<sup>1</sup>
                      </p>
                    </Callout>
                  </div>
                </div>
                <div>&nbsp;</div>
              </Tabs.Pane>
              <Tabs.Pane name="CHANGE IN BMI PERCENTILE" key="3">
                <>
                  <div className="row md_mt-1">
                    <div className="column small-12 large-7">
                      <img
                        src={ImageChangeInBmiPercentile}
                        className="mb-1 md_mb-2"
                        alt="Change in BMI Percentile Chart"
                      />
                      <HangingRef
                        mb0
                        symbol=""
                        copy={
                          <>
                            Figure modeled after Gulati AK, Kaplan DW, Daniels
                            SR. Clinical tracking of severely obese children: a
                            new growth chart. <i>Pediatrics</i>.
                            2012;130(6):1136-1140.
                          </>
                        }
                      />
                    </div>
                    <div className="column small-12 large-5">
                      <div className="hide-for-large">&nbsp;</div>
                      <div className="hide-for-large">&nbsp;</div>
                      <div className="interior-menu--grey">
                        <h2 className="h1 md_pr-3">
                          IMCIVREE significantly reduced the severity of obesity
                          due to BBS<sup>1,3,8-12</sup>
                        </h2>
                        <p className="text-left mr-1">
                          This chart is a visual representation of what a
                          hypothetical child with BBS who started IMCIVREE at
                          age 12* may experience in BMI reduction after 1 year
                          and 2 years, based on data from the phase 3 trial and
                          a separate long-term extension trial
                        </p>
                      </div>
                      <div>&nbsp;</div>
                      <Modal id="bmi-24-month-long-term-3" />
                    </div>
                  </div>
                  <div>&nbsp;</div>

                  <HangingRef
                    mb0
                    symbol="*"
                    isAsterisk
                    copy={
                      <>
                        Growth chart is based on females 2 to 20 years of age
                        and is for illustrative purposes only.
                      </>
                    }
                  />
                  <div>&nbsp;</div>
                </>
              </Tabs.Pane>
            </Tabs>
          </div>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div className="text-center">
          <Button
            copy="See hunger reduction"
            url="/hcp/bbs/hunger-reduction/"
          />
        </div>
        <div>
          <p className="references fw-normal mt-2 md_mt-3">
            <b>References: 1.</b> Data on file. Rhythm Pharmaceuticals, Inc.
            Boston, MA. <b>2.</b> Haqq AM et al.{" "}
            <em>Lancet Diabetes Endocrinol.</em> 2022;10(12):859-868.
            doi:10.1016/S2213-8587(22)00277-7. Supplemental appendix available
            at:
            https://www.thelancet.com/journals/landia/article/PIIS2213-8587(22)00277-7/fulltext.{" "}
            <b>3.</b> Grossman DC et al; US Preventive Services Task Force.{" "}
            <em>JAMA.</em> 2017;317(23):2417-2426. <b>4.</b> IMCIVREE
            [prescribing information]. Boston, MA. Rhythm Pharmaceuticals, Inc.{" "}
            <b>5.</b> Argente J et al. Endocrine Society Annual Meeting. Poster
            ODP606. June 11-14, 2022. <b>6.</b> Vanderwall C et al.{" "}
            <em>BMC Pediatrics.</em> 2018;18(1):187. <b>7.</b> Defining child
            BMI categories. Centers for Disease Control and Prevention. Accessed
            August 11, 2023.
            https://www.cdc.gov/obesity/basics/childhood-defining.html.{" "}
            <b>8.</b> Centers for Disease Control and Prevention. 2000 CDC
            Growth Charts for the United States: Methods and Development.
            Accessed August 11, 2023.
            https://www.cdc.gov/nchs/data/series/sr_11/sr11_246.pdf. <b>9.</b>{" "}
            Kelly AS et al. C<em>irculation.</em> 2013;128(15):1689-1712.{" "}
            <b>10.</b> Gulati AK et al. <em>Pediatrics.</em>{" "}
            2012;130(6):1136-1140. <b>11.</b> Racette SB et al.{" "}
            <em>BMC Pediatr.</em> 2017;17(1):130. <b>12.</b> Argente J et al.
            The Pediatric Endocrine Society Annual Meeting. Poster 155. April
            28-May 1, 2022.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default PediatricBmiZscore
