import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";

const Tabs = (props) => {
  const [active, setActive] = useState(1);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const tabsRef = useRef(null);

  useEffect(() => {
    setActive(1);

    const handleScroll = () => {
      if (tabsRef.current) {
        const tabsBottom = tabsRef.current.getBoundingClientRect().bottom;
    
        // Show the button if the user has scrolled 200px past the tabs
        if (tabsBottom < +200) {
          setShowBackToTop(true);
        } else {
          setShowBackToTop(false);
        }
      }
    };    

    // Check if it's a mobile device
    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
      window.addEventListener('scroll', handleScroll);
    }

    // Clean up the scroll event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleActive = (key) => {
    if (props.tabchange !== undefined) {
      props.tabchange(key);
    }
    setActive(parseInt(key));
  };

  const handleBackToTop = () => {
    if (tabsRef.current) {
      // Scroll to the top of the tabs, accounting for a 200px header
      window.scrollTo({ 
        top: tabsRef.current.offsetTop - 200, 
        behavior: 'smooth' 
      });
    }
  };  

  return (
    <div className={`tabs ${props.className}`}>
      <div className="row expanded hide-for-large sub-nav-mb">
        <div className="columns text-center">
          <p>Go to a section</p>
        </div>
      </div>
      <div className="row expanded tabs__deck pt-0" ref={tabsRef}>
        <ul className="tabs__tabs">
          {React.Children.map(props.children, child => {
            return (
              <li className={active === parseInt(child.key) ? 'isActive' : ''}>
                <button onClick={() => handleActive(child.key)}>{child.props.name}</button>
              </li>
            );
          })}
        </ul>
      </div>

      {React.Children.map(props.children, child => {
        return (
          <>
            <div className={active === parseInt(child.key) ? `row expanded tabs__content` : `visually-hidden`}>
              <div className="columns">
                {child}
              </div>
            </div>
          </>
        );
      })}

      {/* Back to Top Button */}
      {showBackToTop && (
        <button 
          className="back-to-top-button" 
          onClick={handleBackToTop} 
          style={{
            position: 'fixed',
            bottom: '147px',
            right: '5px',
            display: 'block',
            padding: '10px 15px',
            backgroundColor: 'rgb(20 122 138)',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            zIndex: '99'
          }}
        >
          Back to Top
        </button>
      )}
    </div>
  );
};

Tabs.Pane = ({ children }) => children;

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tabs;
