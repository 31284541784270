import React from "react"
import HeroBottomButtons from "./HeroBottomButtons"
import Button from "./button"
import DiscoverWorld from "../images/discover_world.png"
import BottomGreenTab from "../images/hero_bottom_green_tab.png"

const bbsContent = {
  title1: "Help your patients with",
  title2: "Bardet-Biedl syndrome",
  title2_white: "(BBS)",
  whiteText:
    "to target impairment in the hypothalamic MC4R pathway, a root cause of hyperphagia and obesity in BBS",
}

const pplContent = {
  title1: "Help your patients with",
  title2: "POMC, PCSK1, or LEPR",
  title2_white: "deficiency",
  whiteText:
    "to target impairment in the hypothalamic MC4R pathway, a root cause of hyperphagia and obesity in POMC, PCSK1, or LEPR deficiency",
}

// bbs by default
const HCPPPLHero = ({ ppl, icons }) => {
  const content = ppl ? pplContent : bbsContent

  return (
    <>
      <div className={"general_hero hcp" + (ppl ? " ppl" : " bbs")}>
        <div className="hero_content">
          <div className="top_title">
            <h2 className="title color-white">{content.title1}</h2>
            <h2 className="title color-neon-green">
              <strong>{content.title2} </strong>
              <span className="color-white">{content.title2_white}</span>
            </h2>
          </div>

          <img
            src={DiscoverWorld}
            className="discover_image"
            alt="Discover a World Beyond Obesity"
          />
          <p className="small hero_actor_portrayals">Actor portrayals.</p>

          <div className="slanted_rectangle desktop-only">
            <div className="slanted_rectangle_text">
              <div className="mb-4">
                <strong>IMCIVREE</strong> is the{" "}
                <strong>first and only </strong>
                <span className="color-teal" style={{ fontWeight: "bold" }}>
                  PRECISION MEDICINE
                </span>{" "}
                {content.whiteText}
                <sup>1,2</sup>
              </div>

              <Button
                type="global-link"
                tealArrow
                copy={
                  <span className="jost-semibold">
                    Explore how IMCIVREE works
                  </span>
                }
                url="/hcp/ppl/moa/"
              />
            </div>
          </div>

          <div
            className="hide-for-large"
            style={{
              width: "100%",
              position: "absolute",
              bottom: 0,
              left: 0,
              zIndex: 200,
            }}
          >
            {/* <img src={BottomGreenTab} width="100%" /> */}
          </div>
        </div>
      </div>

      <div className="under_hcp_hero">
        {/* mobile */}
        <div className="hide-for-large pt-1">
          <div>
            <strong>IMCIVREE</strong> is the <strong>first and only </strong>
            <span className="color-teal" style={{ fontWeight: "bold" }}>
              PRECISION MEDICINE
            </span>{" "}
            {content.whiteText} <sup>1,2</sup>
          </div>

          <Button
            type="global-link"
            tealArrow
            copy={
              <span className="jost-semibold">Explore how IMCIVREE works</span>
            }
            url="/hcp/ppl/moa/"
          />
        </div>

        <HeroBottomButtons icons={icons} />

        <p style={{ fontSize: 16 }}>MC4R=melanocortin-4 receptor.</p>

        <p className="ref" style={{ fontSize: 16, marginBottom: 0 }}>
          <strong>References:</strong> <strong>1.</strong> IMCIVREE [prescribing
          information]. Boston, MA. Rhythm Pharmaceuticals, Inc.{" "}
          <strong>2.</strong> Eneli I et al. <em>Appl Clin Genet</em>.
          2019;12:87-93.
        </p>
      </div>
    </>
  )
}

export default HCPPPLHero
