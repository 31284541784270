import React, {useEffect} from 'react'

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Callout from "../../../components/callout"

import BbsSymptomsMobile from "../../../images/bbs-symptoms-mobile_hcp.png"
import BbsSymptomsDesktop from "../../../images/hallmark-clinical-features-desktop-new_hcp.png"

import HallmarkClinicalFeaturesImage from "../../../components/gatsby-images/hallmark-clinical-features-chart"

const Overview = () => {

  const scrollIntoViewWithOffset = (selector, offset) => {
    window.scrollTo({
      behavior: 'smooth',
      top:
        document.querySelector(selector).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset,
    })
  }

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      console.log('this');
      anchor.addEventListener('click', function (e) {
          e.preventDefault();
          //console.log(document.querySelector(this.getAttribute('href')).getAttribute('id'));
          scrollIntoViewWithOffset('#'+document.querySelector(this.getAttribute('href')).getAttribute('id'), 40);

      });
    });
  }, [])

  return (
    <Layout>
      <Seo
        title="BBS Overview | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="HCPs: Learn about Bardet-Biedl syndrome (BBS), a rare genetic disease of obesity. Please see full Prescribing Information for Important Safety Information."
        ogTitle="Disease Overview | IMCIVREE® (setmelanotide) injection"
        ogDesc="Learn about the disease that IMCIVREE® (setmelanotide) treats. Please see full Prescribing Information for Important Safety Information."
      />
      <div className="content-block hcp-bbs-overview-content-block">
        <div className="row">
          <div className="column">
            <h1 className="h1">BBS is a rare autosomal recessive ciliopathy that is clinically and genetically diverse<sup>1,2</sup></h1>
            <h2 className="h2">Almost all major body systems contain primary cilia, which are vital to several biological processes<sup>3,4</sup></h2>
          </div>
        </div>
        </div>
        <div>&nbsp;</div>
        <div className="h2" id="bbs-diagnosis">BBS ciliary dysfunction impairs various systems throughout the body<sup>3</sup></div>

        <img
        src={BbsSymptomsDesktop}
        alt="Body diagram identifying BBS symptoms in the brain, kidney, eyes, heart, reproductive, endocrine, and skeletal systems"
        className="hide-for-small-only md_my-2"
      />

      <img
      src={BbsSymptomsMobile}
      alt="Body diagram identifying BBS symptoms in the brain, kidney, eyes, heart, reproductive, endocrine, and skeletal systems"
      className="hide-for-medium hide-for-large"
    />

      <Callout
        right
        className="light-teal right text-left"
      >
        <p>Primary cilia dysfunction within each organ system contributes to the highly variable phenotype in BBS<sup>10</sup></p>
      </Callout>

      <div className="content-block pt-0 md_pt-0">

        <div className="text-center" style={{marginTop: `60px`, marginBottom: `60px`}}>
            <Button copy="Discover different types of obesity" url="/hcp/bbs/types-of-obesity/" />
        </div>

        <div className="references md_mt-1">
          <strong>References: 1.</strong> Manara E et al. <em>Ital J Pediatr.</em> 2019;45(1):72. 
          {" "}<strong>2.</strong> Forsythe E et al. <em>Front Pediatr.</em> 2018;6:23. doi:10.3389/fped.2018.00023. 
          {" "}<strong>3.</strong> Blaess S et al. <em>J Clin Invest.</em> 2021;131(8):e148903. doi:10.1172/JCI148903.v. 
          {" "}<strong>4.</strong> Pala R et al. <em>Int J Mol Sci.</em> 2017;18(11):2272. doi:10.3390/ijms18112272. 
          {" "}<strong>5.</strong> Eneli I et al. <em>Appl Clin Genet</em>. 2019;12:87-93. 
          {" "}<strong>6.</strong> Beales PL et al. <em>J Med Genet.</em> 1999;36(6):437-446. 
          {" "}<strong>7.</strong> Forsythe E et al. <em>Eur J Hum Genet</em>. 2013;21(1):8-13.
          {" "}<strong>8.</strong> Florea L et al. <em>Genes (Basel).</em> 2021;12(9):1353. doi:10.3390/genes12091353. 
          {" "}<strong>9.</strong> Majumdar U et al. <em>BMJ Case Rep.</em> 2012;2012:bcr1220115320.
          {" "}<strong>10.</strong> Zaghloul NA et al. <em>J Clin Invest.</em> 2009;119(3):428-437. doi:10.1172/JCI37041. 
        </div>
      </div>
    </Layout>
  )
}

export default Overview
