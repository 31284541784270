import React from 'react'
import Helmet from "react-helmet";

const PatientPplSd =()=>{

	let dataOne = { "@context": "https://urldefense.com/v3/__http://www.schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-IwkRK3Q$  ",
		"@type": "MedicalWebPage",
		"description": "IMCIVREE is a prescription medicine used in adults and children 6 years of age and older with obesity due to the genetic conditions pro-opiomelanocortin (POMC), proprotein convertase subtilisin/kexin type 1 (PCSK1), or leptin receptor (LEPR) deficiency, to help them lose weight and keep the weight off.",
		"sourceOrganization":{
			"@type": "Corporation",
			"name": "Rhythm Pharmaceuticals",
			"url": "https://urldefense.com/v3/__https://www.rhythmtx.com/__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-Re6XJxA$  ",
			"tickerSymbol": "RHTYM",
	        "legalName": "Rhythm Pharmaceuticals, Inc.",
			"logo": {
	          	"@type": "ImageObject",
	          	"url": "https://urldefense.com/v3/__https://www.rhythmtx.com/wp-content/uploads/2020/11/Rhythm_Logo_CMYK_EPS-registration-mark-1.svg__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-zP3R6Fc$  "
	         	 },
		  "telephone": "833-789-6337",
	       "address": {
	    		"@type": "PostalAddress",
	    		"addressCountry": "USA",
			    "addressLocality": "Boston",
	    		"addressRegion": "Massachusetts",
	    		"postalCode": "02116",
	    		"streetAddress": "222 Berkeley Street, 12th Floor"
				},
	          "sameAs": [
	          	"https://urldefense.com/v3/__https://www.linkedin.com/company/rhythm-pharmaceuticals-inc-/__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-ci7RpGg$  ",
	          	"https://urldefense.com/v3/__https://twitter.com/rhythmpharma__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-wx13mk4$  ",
	          	"https://urldefense.com/v3/__https://finance.yahoo.com/quote/RYTM__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-qOMuWRM$  ",
	          	"https://urldefense.com/v3/__https://www.bloomberg.com/profile/company/RYTM:US__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp--o-t3ZU$  ",
	          	"https://urldefense.com/v3/__https://www.reuters.com/markets/companies/RYTM.OQ/__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-_DYrDbk$  "
	         	 ],
	          "description": "Rhythm is dedicated to understanding rare genetic diseases of obesity caused by impaired signaling in the central pathway of the brain known as the melanocortin-4 receptor (MC4R) pathway.",
			"Brand": {
				"@type": "Brand",
				"name": "IMCIVREE",
				"url": "https://urldefense.com/v3/__https://imcivree.com__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-AO57yHM$  ",
				"logo": "https://urldefense.com/v3/__https://imcivree.com/images/imcivree-logo.svg__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-ifrG-Dg$  "
			}
		}
	};

	let dataTwo = {
		"@context": "https://urldefense.com/v3/__http://www.schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-IwkRK3Q$  ",
		"@type": "MedicalAudience",
		"name": "Patient",
		"url": "https://urldefense.com/v3/__https://imcivree.com__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-AO57yHM$  ",
		"geographicArea":{
			"@type": "AdministrativeArea",
			"name": "This site is intended for US residents only.",
			"url": "https://urldefense.com/v3/__https://imcivree.com__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-AO57yHM$  "
		}
	};

	let dataThree = {
		"@context": "https://urldefense.com/v3/__http://schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-m8FExX4$  ",
		"@type": "MedicalCondition",
	    "name": [
		    "POMC Deficiency",
		    "PCSK1 Deficiency",
			"LEPR Deficiency"
		  ],
		"signOrSymptom": [
			"obesity",
			"intense hunger"
		]
	};

	let dataFour = {
		"@context": "https://urldefense.com/v3/__http://www.schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-IwkRK3Q$  ",
		"@type": "Drug",
		"name": "IMCIVREE (setmelanotide)",
	    "url": "https://urldefense.com/v3/__https://imcivree.com__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-AO57yHM$  ",
	    "description": "IMCIVREE is a prescription medicine used in adults and children 6 years of age and older with obesity due to the genetic conditions pro-opiomelanocortin (POMC), proprotein convertase subtilisin/kexin type 1 (PCSK1), or leptin receptor (LEPR) deficiency, to help them lose weight and keep the weight off.",
	    "activeIngredient": "setmelanotide",
	    "administrationRoute": "intravenous",
		"doseSchedule": {
	        "@type": "DoseSchedule",
	        "frequency": "IMCIVREE is a once-daily injection used to help reduce weight in people living with POMC, PCSK1, or LEPR deficiency. IMCIVREE has helped people 6 years of age and older lose weight and keep it off."
	        },
	    "warning": "https://urldefense.com/v3/__https://rhythmtx.com/IMCIVREE/prescribing-information.pdf__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-6hG8KPg$  ",
	    "medicineSystem": "evidence-based",
	    "mechanismOfAction": "IMCIVREE helps activate the areas in the brain that control appetite, feeling full, and metabolism to help lose weight and keep it off.",
	    "recognizingAuthority": "FDA",
			"relevantSpecialty": [
				"endocrinology",
				"genetics",
				"ophthalmology",
				"nephrology"
					],
	    "prescribingInfo": "https://urldefense.com/v3/__https://rhythmtx.com/IMCIVREE/prescribing-information.pdf__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-6hG8KPg$  ",
	    "prescriptionStatus": "Prescription-Only Medication",
	    "image": {
	    	"@type": "ImageObject",
	    		"url":"https://urldefense.com/v3/__https://imcivree.com/images/imcivree-logo.svg__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-ifrG-Dg$  "
			}
	};

	let dataFive = { "@context": "https://urldefense.com/v3/__http://schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-m8FExX4$  ",
		 "@type": "MedicalIndication",
		 "name": "IMCIVREE (setmelanotide)",
		 "description": "IMCIVREE is indicated for chronic weight management in adult and pediatric patients 6 years of age and older with monogenic or syndromic obesity due to pro-opiomelanocortin (POMC), proprotein convertase subtilisin/kexin type 1 (PCSK1), or leptin receptor (LEPR) deficiency as determined by an FDA-approved test demonstrating variants in POMC, PCSK1, or LEPR genes that are interpreted as pathogenic, likely pathogenic, or of uncertain significance (VUS)."
	};

	let dataSix = {
	  "@context": "https://urldefense.com/v3/__https://schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-pT-u95Y$  ",
	  "@type": "FAQPage",
	  "mainEntity": [{
	    "@type": "Question",
	    "name": "I’ve always struggled with obesity. Can I take IMCIVREE?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "IMCIVREE is a treatment for obesity due to POMC, PCSK1, or LEPR deficiency confirmed through a genetic test. The test must show that the change, or variant, is considered pathogenic, likely pathogenic, or uncertain. IMCIVREE should not be used by someone with obesity due to suspected POMC, PCSK1, or LEPR deficiency not confirmed by genetic testing (benign or likely benign result) or someone with another type of obesity not related to POMC, PCSK1, or LEPR deficiency, including obesity associated with other genetic conditions and general obesity. It’s always best to talk to your healthcare provider to determine if a treatment is right for you."
	    }
	  },{
	    "@type": "Question",
	    "name": "How much weight can I expect to lose?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Each individual is different, and results can vary from person to person. In clinical studies of IMCIVREE: 80% of people (8 out of 10) with obesity due to POMC or PCSK1 deficiency lost at least 10% of their body weight at 1 year; people with obesity due to POMC or PCSK1 deficiency had a 23.1% average reduction in weight from baseline after 1 year. 46% of people (5 out of 11) with obesity due to LEPR deficiency lost at least 10% of their body weight at 1 year; people with obesity due to LEPR deficiency had a 9.7% average reduction in weight from baseline after 1 year. When treatment was stopped, weight increased. When the withdrawal period ended and treatment was restarted, weight loss continued. You can find more information on IMCIVREE clinical studies here. To determine what may be reasonable for you to expect from treatment, speak to your healthcare provider."
	    }
	  },{
	    "@type": "Question",
	    "name": "Is it safe?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "IMCIVREE may cause serious side effects, including: Male and female sexual function problems. IMCIVREE can cause an erection that happens without any sexual activity in males (spontaneous penile erection) and unwanted sexual reactions (changes in sexual arousal that happen without any sexual activity) in females. If you have an erection lasting longer than 4 hours, get emergency medical help right away. Depression and suicidal thoughts or actions. You or a caregiver should call your healthcare provider right away if you have any new or worsening symptoms of depression. Increased skin pigmentation and darkening of skin lesions (moles or nevi) you already have. These changes happen due to how IMCIVREE works in the body and will go away when you stop using IMCIVREE. You should have a full body skin exam before starting and during treatment with IMCIVREE to check for skin changes. Benzyl alcohol toxicity. Benzyl alcohol is a preservative in IMCIVREE. Benzyl alcohol can cause serious side effects, including death, in premature and low-birth weight infants, who have received medicines that contain benzyl alcohol. IMCIVREE should not be used in premature and low-birth weight infants. The most common side effects of IMCIVREE include darkening of the skin, injection site reactions, nausea, headache, diarrhea, stomach pain, vomiting, depression, and an erection that happens without any sexual activity in males. These are not all the possible side effects of IMCIVREE. Call your doctor for medical advice about side effects. You may report side effects to FDA at 1-800-FDA-1088 or Rhythm Pharmaceuticals at 1-833-789-6337."
	    }
	  },{
	    "@type": "Question",
	    "name": "Will nausea and vomiting get better the longer I take IMCIVREE?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "It's best to reach out to your current healthcare provider for more information. In phase 3 clinical studies evaluating IMCIVREE for the treatment of obesity due to POMC, PCSK1, and LEPR deficiency, nausea was reported by 56% of people taking IMCIVREE, and vomiting was reported by 30% of people taking IMCIVREE. Responses vary, but these 2 effects were most often reported in the first month after starting treatment, and were reported less frequently over time."
	    }
	  },{
	    "@type": "Question",
	    "name": "How long should I expect to take IMCIVREE?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "IMCIVREE must be taken daily to be effective. In clinical trials, when IMCIVREE was stopped, individuals experienced weight gain. When the withdrawal period ended and treatment was restarted, weight loss continued. Speak to your healthcare provider to determine the best treatment plan for you."
	    }
	  },{
	    "@type": "Question",
	    "name": "How do I know if this is covered by insurance?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Insurance coverage varies from plan to plan. We invite you to contact Rhythm InTune at 1-855-206-0815. Our team members would be happy to help you look into options and plan next steps."
	    }
	  },{
	    "@type": "Question",
	    "name": "Are there options to help with the cost?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "We invite you to contact Rhythm InTune at 1-855-206-0815 to learn more about financial support options that may be available."
	    }
		},{
	    "@type": "Question",
	    "name": "Where can I go if I have more questions about IMCIVREE?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "The first place to turn is always your healthcare provider, who can determine the right treatment for you. Rhythm InTune may also be able to help with questions about taking IMCIVREE, insurance coverage, financial support programs, and ongoing support. Contact Rhythm InTune at 1-855-206-0815."
	    }
	  }]
	};

	return(
		<>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(dataOne)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataSix)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataTwo)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataThree)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataFour)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataFive)}
				</script>
			</Helmet>
		</>
	);
}

export default PatientPplSd
