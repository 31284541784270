import React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Callout from "../../../components/callout-smallWidth"

import OvereatingDisordersChart from "../../../components/overeating-disorders-chart"
import PatientCaregiverQuote from "../../../components/patient-caregiver-quote"

import ImgAnnSabrina from "../../../images/ann-and-sabrina.jpg"
import ImgRachelFamily from "../../../images/rachel-and-family-3.png"
import ImgSabrina from "../../../images/sabrina-2.png"
import ImgKat from "../../../images/hunger-bbs-profile03.png"
import ImgChristyDavis from "../../../images/christy_davis.png"
import ImgDrOkorie from "../../../images/dr-okorie.png"
import ImgAlainaVidmar from "../../../images/alaina_vidmar.png"
import GreenPlusIcon from "../../../images/icon-green-plus.svg"

import HyperphagiaVideo from "../../../components/hyperphagia-video"
import VideoTranscript from "../../../components/video-transcript"
import HyperphagiaVideoTranscript from "../../../components/template-partials/hcp/bbs/hyperphagia-bbs-video-transcript"
import NewModal from "../../../components/NewModal"

const Hyperphagia = () => {
  const [showCareGiverModal, setShowCareGiver] = React.useState(false)
  const [showClinicianModal, setShowClinician] = React.useState(false)

  return (
    <Layout>
      <Seo
        title="Hyperphagia in BBS | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="HCPs: Learn how to identify hyperphagia in your patients. Please see full Prescribing Information for Important Safety Information."
        ogTitle="Hyperphagia in BBS | IMCIVREE® (setmelanotide) injection"
        ogDesc="Learn about a common feature in patients. Please see full Prescribing Information for Important Safety Information."
      />
      
      <div className="content-block hcp-bbs-overview-content-block">
        <div className="row">
          <div className="column">
            <h1 className="h1">
            Not all hunger is the same: hyperphagia is a key symptom of MC4R pathway impairment
            </h1>
            <h2 className="h2">
              Identifying the type of hunger your patient has can help lead to
              optimal management of their specific disease
            </h2>
          </div>
        </div>

        <div className="large">
          <OvereatingDisordersChart />
        </div>
      
        <div class="row">

          <div class="columns small-12 medium-6 large-3 md_mr-1 md_mt-2 mt-1">
            <button onClick={() => setShowCareGiver(true)}>
              <img
                src={GreenPlusIcon}
                alt="Green plus icon"
                className="modal-trigger-button-plus"
                style={{ marginRight: 8 }}
              />
              <strong className="color-teal">Caregiver quotes</strong>
            </button>
          </div>
           
          <div class="columns small-12 medium-6 large-4 md_mt-2  mt-1">
            <button onClick={() => setShowClinician(true)}>
              <img
                src={GreenPlusIcon}
                alt="Green plus icon"
                className="modal-trigger-button-plus"
                style={{ marginRight: 8 }}
              />
              <strong className="color-teal">Clinician quotes</strong>
            </button>
          
            <p style={{ fontSize: 12, marginLeft: 36 }}>
              AAP=American Academy of Pediatrics; MC4R=melanocortin-4 receptor;
              OMA=Obesity Medicine Association.
            </p>
          </div>
        </div>

        </div>

        <Callout right className="light-teal right text-left">
          <p>
            If you have patients with hyperphagia and early-onset obesity, it
            may be time to take a closer look
          </p>
        </Callout>


      <div className="content-block">
        <div className="row">
          <div className="column">
            <h2 className="h1">Real experiences with hyperphagia</h2>
            <h3 className="h2">
              Learn about its daily impact on families and how physicians
              differentiate hyperphagia from other overeating behaviors to
              diagnose and manage it
            </h3>
          </div>
        </div>

        <HyperphagiaVideo />
        <VideoTranscript>
          <HyperphagiaVideoTranscript />
        </VideoTranscript>
      </div>


      <div className="content-block" style={{ padding: `0 2rem` }}>
        <div className="text-center mt-2" style={{ marginBottom: `60px` }}>
          <Button copy="See how IMCIVREE works" url="/hcp/bbs/moa/" />
        </div>

        <p className="footnote">
          AAP=American Academy of Pediatrics; MC4R=melanocortin-4 receptor;
          OMA=Obesity Medicine Association.
        </p>

        <div className="references footnote mt-1 md_mt-1">
          <strong>References: 1. </strong>Haqq AM et al.{" "}<em>Child Obes.</em>{" "}2021;17(4):229-240.{" "}
          <strong>2.</strong>{" "}Espel-Huynh HM et al.<em>{" "}Obes Sci Pract.</em>{" "}2018;4(3):238- 249. doi:10.1002/osp4.161.{" "}
          <strong>3.</strong>{" "}Tanajewski 2023-{" "}<em>Food Quality and Preference</em>{" "}109 (2023) 104889.{" "}
          <strong>4.</strong>{" "}Hampl SE et al.<em>{" "}Pediatrics.</em>{" "}2023;151(2):e202206064. doi:10.1542/peds.2022-060640.{" "}
          <strong>5.</strong>{" "}NIH_Symptoms and Causes of Binge Eating Disorder–NIDDK https://www.niddk.nih.
          gov/health-information/weight-management/binge-eating-disorder/symptoms-causes{" "}
          <strong>6.</strong>{" "}Eneli I et al.<em>{" "}Appl Clin Genet.</em>{" "}2019;12:87-93.{" "}
          <strong>7.</strong>{" "}Heymsfield SB et al.<em>{" "}Obesity (Silver Spring).</em>{" "}2014;22(suppl 1):S1-S17. doi:10.1002/oby.20646.{" "} 
          <strong>8.</strong>{" "}Forsythe E et al.<em>{" "}Orphanet J Rare Dis.</em> 2023 Jan 16;18(1):12.{" "}
          <strong>9.</strong>{" "}Ulrich et al.<em>{" "}2014 Cell Metabolism.</em>  2014/p914/col2/par1/lin6-8.{" "}
          <strong>10.</strong>{" "}Sherafat-Kazemzadeh R et al.<em>{" "}Pediatr Obes.</em> 2013;8(5):e64-e67. doi:10.1111/j.2047-6310.2013.00182.x.{" "}
          <strong>11.</strong>{" "}Tondt J et al.<em>{" "}Obesity Algorithm®{" "}2023.</em> Obesity Medicine Association; 2023. Accessed June 13, 2023. https://obesitymedicine.org/obesity-algorithm.{" "}
          <strong>12.</strong>{" "}Ervin C et al.<em>{" "}Adv Ther.</em> 2023;40(5):2394-2411. doi:10.1007/s12325-023- 02443-y.
        </div>
      </div>

      <NewModal
        heading="CAREGIVER QUOTES"
        headingStyle={{ color: "#007A8A", fontWeight: "bold" }}
        open={showCareGiverModal}
        onClose={() => setShowCareGiver(false)}
      >
        <PatientCaregiverQuote
          order="reverse"
          imgSrc={ImgKat}
          alt="Caregiver of a child living with BBS"
          copy="The most challenging symptom (of BBS) was hyperphagia. We did not anticipate the severity of the hunger. It is much more intense and ever-present, and impacted everything we did. Even a joyful occasion like a birthday party was stressful. While all the kids were socializing, Reed was only concerned about food and not just concerned, he was infatuated with it."
          signoff="&#8212; Kat, caregiver of a child living with BBS"
        />
        <PatientCaregiverQuote
          imgSrc={ImgAnnSabrina}
          alt="Caregiver of a person living with BBS"
          copy="After her first birthday, we noticed a rapid weight gain, and she threw tantrums all the time and was very difficult to manage. Looking back, I see this was most likely due to how hungry she felt."
          signoff="&#8212; Ann, caregiver of a person living with BBS"
        />
        <PatientCaregiverQuote
          order="reverse"
          imgSrc={ImgRachelFamily}
          alt="Caregiver of a child living with BBS"
          copy={
            <>
              While Wyatt and Carson have the same variants of the{" "}
              <em>BBS10</em> gene, only Wyatt has hyperphagia and obesity. I
              couldn't take the kids out by myself because Wyatt needed constant
              supervision to make sure he wasn't stealing food. I have to lock
              our fridge, trash, and pantry.
            </>
          }
          signoff="&#8212; Rachel, caregiver of a child living with BBS"
        />
      </NewModal>

      <NewModal
        heading="CLINICIAN QUOTES"
        headingStyle={{ color: "#007A8A", fontWeight: "bold" }}
        open={showClinicianModal}
        onClose={() => setShowClinician(false)}
      >
        <PatientCaregiverQuote
          imgSrc={ImgChristyDavis}
          alt="Obesity and Weight Management Specialist"
          copy="I absolutely see varying degrees of hyperphagia, from extreme cases where patients have locks on cabinets, and do not keep food in the home, to other less extreme cases where they've learned to adapt around it. I have one patient that has never admitted to having hyperphagia. In her words, sometimes she just suffers through it, or does other things, like drinks way too much water to try to feel full."
          signoff="&#8212; Christy Davis, Obesity and Weight Management Specialist"
        />
        <PatientCaregiverQuote
          order="reverse"
          imgSrc={ImgDrOkorie}
          alt="Pediatric Cardiologist"
          copy="I had a parent say what he worried most about was not other health consequences, but the child's ability to learn. That resonated for me. All I could think was if my child is constantly thinking about food, how are they ever going to learn? It really drove it home for me."
          signoff="&#8212; Uzoma Okorie, Pediatric Cardiologist"
        />
        <PatientCaregiverQuote
          imgSrc={ImgAlainaVidmar}
          alt="Pediatric Endocrinologist"
          copy="I encourage clinicians to ask specific questions about how the feeling of hunger is impacting their patient’s quality of life and ability to function in their life. Particularly around school, work, caregiving relationships, as well as around pleasure and fun. When you can see that hunger is negatively impacting or preoccupying all of those spaces, it is a really good cue they are experiencing hyperphagia. It needs to be high on our priority list of how we are going to help our patients, not only with their weight trajectories, but also with their hyperphagia."
          signoff="&#8212; Alaina Vidmar, Pediatric Endocrinologist"
        />
      </NewModal>
    </Layout>
  )
}

export default Hyperphagia
