import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types"
import Helmet from 'react-helmet'
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import MainLogo from '../../global-components/main-logo'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faUser, faEnvelope, faFileAlt, faArrowDown } from "@fortawesome/free-solid-svg-icons";

import * as Utils from "../../../../helpers/utils"

const Header = ({ data }) => (
  <></>
)

export default function MyHeader(props) {
  const [navOpen, setNavOpen] = useState(false)
  const [hasChild, setHasChild] = useState(false)
  const url = typeof window !== "undefined" ? window.location.pathname : ""
  const toggleNav = e => {
    let width = document.body.clientWidth
    if (width <= 1024) {
      e.currentTarget.classList.toggle("isOpen")
      document
        .querySelector(`.main-header__mobile-menu-wrapper`)
        .classList.toggle("isOpen")
      if (navOpen) {
        setNavOpen(false)
      } else {
        setNavOpen(true)
      }
      e.preventDefault()
    } else {
      return true
    }
  }

  const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const getCookie = cname => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const initFontSize = () =>{
    // initialize font-size cookie
    if (getCookie("font-size")){
      document
        .querySelector(`html`)
        .className = getCookie("font-size");
    }
  }

  const fontResize = e => {
    if (e){
      setCookie("font-size", e, 1); //expires in 1 day
      initFontSize();
    }
  }

  const toggleActive = e => {
    e.currentTarget.classList.toggle("active")

    //remove active class from dom that's not current target
    let elems = document.querySelectorAll(".active")
    ;[].forEach.call(elems, function (el) {
      if (e.currentTarget !== el) {
        el.classList.remove("active")
      }
    })
    e.stopPropagation()
  }

  const toggleMobileDrawer = e => {
    let width = document.body.clientWidth
    e.currentTarget.parentNode.classList.add("isSelected")
    //remove active class from dom that's not current target
    let elems = document.querySelectorAll(".isSelected")
    ;[].forEach.call(elems, function (el) {
      if (e.currentTarget.parentNode !== el) {
        el.classList.remove("isSelected")
      }
    })
    e.stopPropagation()
    //console.log(e.currentTarget.parentNode)
    if (width <= 1024) {
      let parent = e.currentTarget.parentNode
      let target = e.currentTarget

      if (target.classList.contains("hasChild")) {
        // Fall 2024 Update
        const menu = document.querySelector('.main-nav__menu');
        const children = Array.from(menu.children);
        // Remove "isOpen" from all children except the current one
        children.forEach(child => {
          if (child !== parent) {
            child.classList.remove("isOpen");
          }
        });
        // Toggle "isOpen" on the current button
        parent.classList.toggle("isOpen");
        e.preventDefault()
      } else {
        document
          .querySelector(`.main-header__mobile-menu-wrapper`)
          .classList.toggle("isOpen")
      }
    } else {
      return true
    }
  }

  ;<StaticQuery
    query={graphql`
      query SiteTitleQuery4 {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />


  const setActive = () => {
    let current = window.location.pathname;
    let elems = document.querySelectorAll("header ul li > a[href]");
    
  // Remove isActive class from all elements within .main-nav
  document.querySelectorAll("header nav.main-nav .isActive").forEach(el => {
    el.classList.remove("isActive");
  });
  
    [].forEach.call(elems, function (el) {
      // if the current path is like this link, make it active
      if (el.getAttribute("href") === current) {
        el.parentNode.classList.add("isActive");
        el.classList.add("isActive");
        let parentClass = el.getAttribute("parentclass");
        if (parentClass) {
          let parentActive = document.querySelector("." + parentClass);
          parentActive.classList.add("isActive");
        }
      }
    });
  }

  const isiHandler = e => {
    e.preventDefault();
    let scrollByOffset = 0, buttonTarget = document.querySelector('.main-nav-toggle');
    if (buttonTarget.classList.contains('isOpen') === true) {
      toggleNav(e);
      buttonTarget.classList.remove('isOpen')
    };
    scrollByOffset = (scrollByOffset - document.querySelector('header').offsetHeight) - 32;
    document.getElementById('isiContainer').scrollIntoView();
    window.scrollBy(0, scrollByOffset);
  };

  useEffect(() => {
    //check for utm
    Utils.utmCheck();
    setActive()
    // initialize font-size cookie
    initFontSize();
    const onClick = e => {
      let elems = document.querySelectorAll(".active")
      ;[].forEach.call(elems, function (el) {
        el.classList.remove("active")
      })
    }
    document.body.addEventListener("click", onClick)
    return () => {
      document.body.removeEventListener("click", onClick)
    }
  }, [])

  return (
    <>
      {/* Use helmet to dynamically add a body class*/}
      {navOpen && <Helmet bodyAttributes={{ class: "hcp-ppl nav-lock" }} />}
      {!navOpen && <Helmet bodyAttributes={{ class: "hcp-ppl" }} />}
      {hasChild && <Helmet bodyAttributes={{ class: "hcp-ppl hasChild" }} />}
      {/* Use helmet to dynamically add a body class*/}

      <header className="main-header">

        {/* Eyebrow - Fall 2024 */}
        <div className="eyebrow-hcp">
          <p>Now approved for children 2 to &lt;6 years old</p>
        </div>

        <div className='row mobile-only'>
          <div className='columns'>
            <ul className='mobile-only-tertiary'>
              <li className="text-center"><a href="https://rhythmtx.com/IMCIVREE/prescribing-information.pdf" target="_blank">Prescribing<br/>Information</a></li>
              <li className="text-center"><a onClick={isiHandler}>Important Safety<br/>Information</a></li>
              <li className="text-center"><Link to="/ppl/">Visit<br/>Patient Site</Link></li>
            </ul>
          </div>
        </div>

        <div className="row row--inner main-header__mobile-tray">
          <div className="columns large-2">
            <MainLogo to="/hcp/ppl/" location="header"/>
            <button className="main-nav-toggle hide-for-large" onClick={(e) => toggleNav(e)}>
              <span></span>
              <span></span>
              <span></span>
              <span className="visually-hidden">toggle mobile menu</span>
            </button>
          </div>
          <div className='columns large-10'>
            
          <div className="utility-nav-wrapper">
              <div className="tertiary-nav desktop-only">
                  <ul className="nav-items">
                    <li className=""><div className="site-label">Site intended for US residents only.</div></li>
                    <li className='slanted'><a href="https://rhythmtx.com/IMCIVREE/prescribing-information.pdf" target="_blank">Prescribing Information</a></li>
                    <li className='slanted'><a onClick={isiHandler}>Important Safety Information</a></li>
                    <li className='slanted '><Link to="/ppl/">Visit Patient Site</Link></li>
                    <li className="other-indications" onClick={(e) => toggleActive(e)}>Other Indications
                      <ul className="nav-sub-items">
                      <li><Link to="/hcp/ppl/">POMC, PCSK1, and LEPR deficiency</Link></li>
                      <li><Link to="/hcp/bbs/">Bardet-Biedl syndrome</Link></li>
                    </ul>
                    </li>
                  </ul>
              </div>
              <div className="secondary-nav desktop-only">
                  <ul className="nav-items">
                      <li><Link to="/hcp/ppl/sign-up/"> <FontAwesomeIcon icon={faUser}/> Contact a Representative <FontAwesomeIcon className="caret" icon={faAngleRight}/></Link></li>
                      <li><a href="/Start_Form.pdf" target="_blank"><FontAwesomeIcon icon={faFileAlt}/> IMCIVREE Prescription Start Form <FontAwesomeIcon className="caret" icon={faArrowDown}/></a></li>
                      <li className="font-size"><span className="small" onClick={(e) => fontResize("gsmall")}>A</span> <span className='pipe'>|</span> <span className="normal" onClick={(e) => fontResize("gmedium")}>A</span> <span className='pipe'>|</span> <span className="large" onClick={(e) => fontResize("glarge")}>A</span></li>
                  </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row row--inner main-header__mobile-menu-wrapper pb-0">
          <div className="columns main-column">

            <nav className="main-nav ppl_hcp_nav">
              <ul className="main-nav__menu">
              
              <li className='overview'>
                  <Link to="/hcp/ppl/overview/" className={'hasChild'} onClick={(e) => toggleMobileDrawer(e)}><span>Diseases Overview</span></Link>
                  <div className="mega-wrapper">
                    <div className="main-nav__mega-inner mega-inner-hcp-ppl">
                      <div className="row align-middle align-justity">
                        <div className="columns small-12 large-expand">
                          <ul className="main-nav__child-menu">
                            <li><Link to="/hcp/ppl/overview/" parentclass="overview" className="jost-semibold">Diseases overview</Link></li>
                            <li><Link to="/hcp/ppl/types-of-obesity/" parentclass="overview" className="jost-semibold">Types of obesity</Link></li>
                            <li><Link to="/hcp/ppl/identifying-hyperphagia/" parentclass="overview" className="jost-semibold">Identifying hyperphagia</Link></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sub-nav-hcp-ppl">
                    <ul>
                      <li><Link to="/hcp/ppl/overview/" parentclass="overview" className="jost-semibold">Diseases overview</Link></li>
                      <li><Link to="/hcp/ppl/types-of-obesity/" parentclass="overview" className="jost-semibold">Types of obesity</Link></li>
                      <li><Link to="/hcp/ppl/identifying-hyperphagia/" parentclass="overview" className="jost-semibold">Identifying hyperphagia</Link></li>
                    </ul>
                  </div>
                </li>

                <li><Link className="no-child" onClick={(e) => toggleMobileDrawer(e)} to="/hcp/ppl/moa/"><span>Mechanism of Action</span></Link></li>
                
                <li className='efficacy'>
                  <Link to="/hcp/ppl/study-design/" className={'hasChild'} onClick={(e) => toggleMobileDrawer(e)}><span>Efficacy</span></Link>
                  <div className="mega-wrapper">
                    <div className="main-nav__mega-inner mega-inner-hcp-ppl">
                      <div className="row align-middle align-justity">
                        <div className="columns small-12 large-expand">
                          <ul className="main-nav__child-menu">
                            <li><Link to="/hcp/ppl/study-design/" parentclass="efficacy" className="jost-semibold">Study design</Link></li>
                            <li><Link to="/hcp/ppl/weight-reduction/" parentclass="efficacy" className="jost-semibold">Weight reduction</Link></li>
                            <li><Link to="/hcp/ppl/hunger-reduction/" parentclass="efficacy" className="jost-semibold">Hunger reduction</Link></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sub-nav-hcp-ppl">
                    <ul>
                      <li><Link to="/hcp/ppl/study-design/" parentclass="efficacy" className="jost-semibold">Study design</Link></li>
                      <li><Link to="/hcp/ppl/weight-reduction/" parentclass="efficacy" className="jost-semibold">Weight reduction</Link></li>
                      <li><Link to="/hcp/ppl/hunger-reduction/" parentclass="efficacy" className="jost-semibold">Hunger reduction</Link></li>
                    </ul>
                  </div>
                </li>

                <li><Link className="no-child" onClick={(e) => toggleMobileDrawer(e)} to="/hcp/ppl/safety-profile/" ><span>Safety Profile</span></Link></li>
                
                <li className='dosing1'><Link className="no-child" to="/hcp/ppl/dosing-administration/" onClick={(e) => toggleMobileDrawer(e)}><span>Dosing & Administration</span></Link></li>
                
                <li><Link className="no-child" to="/hcp/ppl/prescribing-imcivree/" onClick={(e) => toggleMobileDrawer(e)}><span>Prescribing IMCIVREE</span></Link></li>
                
                <li className='support'>
                  <Link to="/hcp/ppl/patient-support/" className={ 'hasChild'} onClick={(e) => toggleMobileDrawer(e)}><span>Patient Support & Resources</span></Link>
                  <div className="mega-wrapper">
                    <div className="main-nav__mega-inner mega-inner-hcp-ppl">
                      <div className="row align-middle align-justity">
                        <div className="columns small-12 large-expand">
                          <ul className="main-nav__child-menu">
                              <li><Link parentclass="support" className="jost-semibold" to="/hcp/ppl/patient-support/">Patient support</Link></li>
                              <li><Link parentclass="support" className="jost-semibold" to="/hcp/ppl/patient-resources/">Downloadable resources</Link></li>
                              <li><Link parentclass="support" className="jost-semibold" to="/hcp/ppl/faq/">Frequently asked questions</Link></li>
                            </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sub-nav-hcp-ppl">
                    <ul>
                      <li><Link parentclass="support" className="jost-semibold" to="/hcp/ppl/patient-support/">Patient support</Link></li>
                      <li><Link parentclass="support" className="jost-semibold" to="/hcp/ppl/patient-resources/">Downloadable resources</Link></li>
                      <li><Link parentclass="support" className="jost-semibold" to="/hcp/ppl/faq/">Frequently asked questions</Link></li>
                    </ul>
                  </div>
                </li>
              </ul>

              <div className='mobile-only-secondary-utility-nav'>
                <ul className='finess'><li className="pb-0">Other Indications
                    <ul className="nav-sub-items">
                    <li><Link to="/hcp/ppl/">POMC, PCSK1, and LEPR deficiency</Link></li>
                    <li><Link to="/hcp/bbs/">Bardet-Biedl syndrome</Link></li>
                  </ul>
                  </li>
                </ul>
                <ul className='finess'>
                    <li><Link to="/hcp/ppl/sign-up/"><FontAwesomeIcon icon={faUser}/> Contact a Representative <FontAwesomeIcon className="caret" icon={faAngleRight}/></Link></li>
                    <li><a href="/Start_Form.pdf" target="_blank"><FontAwesomeIcon icon={faFileAlt}/> IMCIVREE Prescription Start Form <FontAwesomeIcon className="caret caret--sm" icon={faArrowDown}/></a></li>
                </ul>
            </div>

          </nav>
        </div>
      </div>
    </header>
    </>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}