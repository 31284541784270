import React from 'react'
import Helmet from "react-helmet";

const PatientBbsSd =()=>{

	let dataOne = { "@context": "https://urldefense.com/v3/__http://www.schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-IwkRK3Q$  ",
		"@type": "MedicalWebPage",
		"description": "IMCIVREE is a prescription medicine used in adults and children 6 years of age and older with obesity due to Bardet-Biedl syndrome.",
		"sourceOrganization":{
			"@type": "Corporation",
			"name": "Rhythm Pharmaceuticals",
			"url": "https://urldefense.com/v3/__https://www.rhythmtx.com/__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-Re6XJxA$  ",
			"tickerSymbol": "RHTYM",
	        "legalName": "Rhythm Pharmaceuticals, Inc.",
			"logo": {
	          	"@type": "ImageObject",
	          	"url": "https://urldefense.com/v3/__https://www.rhythmtx.com/wp-content/uploads/2020/11/Rhythm_Logo_CMYK_EPS-registration-mark-1.svg__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-zP3R6Fc$  "
	         	 },
		  "telephone": "833-789-6337",
	       "address": {
	    		"@type": "PostalAddress",
	    		"addressCountry": "USA",
			    "addressLocality": "Boston",
	    		"addressRegion": "Massachusetts",
	    		"postalCode": "02116",
	    		"streetAddress": "222 Berkeley Street, 12th Floor"
				},
	          "sameAs": [
	          	"https://urldefense.com/v3/__https://www.linkedin.com/company/rhythm-pharmaceuticals-inc-/__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-ci7RpGg$  ",
	          	"https://urldefense.com/v3/__https://twitter.com/rhythmpharma__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-wx13mk4$  ",
	          	"https://urldefense.com/v3/__https://finance.yahoo.com/quote/RYTM__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-qOMuWRM$  ",
	          	"https://urldefense.com/v3/__https://www.bloomberg.com/profile/company/RYTM:US__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp--o-t3ZU$  ",
	          	"https://urldefense.com/v3/__https://www.reuters.com/markets/companies/RYTM.OQ/__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-_DYrDbk$  "
	         	 ],
	          "description": "Rhythm is dedicated to understanding rare genetic diseases of obesity caused by impaired signaling in the central pathway of the brain known as the melanocortin-4 receptor (MC4R) pathway.",
			"Brand": {
				"@type": "Brand",
				"name": "IMCIVREE",
				"url": "https://urldefense.com/v3/__https://imcivree.com__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-AO57yHM$  ",
				"logo": "https://urldefense.com/v3/__https://imcivree.com/images/imcivree-logo.svg__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-ifrG-Dg$  "
			}
		}
	};

	let dataTwo = {
		"@context": "https://urldefense.com/v3/__http://www.schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-IwkRK3Q$  ",
		"@type": "MedicalAudience",
		"name": "Patient",
		"url": "https://urldefense.com/v3/__https://imcivree.com__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-AO57yHM$  ",
		"geographicArea":{
			"@type": "AdministrativeArea",
			"name": "This site is intended for US residents only.",
			"url": "https://urldefense.com/v3/__https://imcivree.com__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-AO57yHM$  "
		}
	};

	let dataThree = {
		"@context": "https://urldefense.com/v3/__http://schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-m8FExX4$  ",
		"@type": "MedicalCondition",
		"name": "Bardet-Biedl Syndrome",
		"alternateName": "BBS",
		"description": "BBS is inherited. It can be passed from parent to child if both parents have variants, or changes, in a BBS gene.",
		"signOrSymptom": [
			"obesity",
			"hyperphagia (insatiable hunger)",
			"renal anomalies",
			"visual impairment",
			"learning disabilities",
			"kidney problems",
			"postaxial polydactyly",
			"hypogonadism"
		]
	};

	let dataFour = {
		"@context": "https://urldefense.com/v3/__http://www.schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-IwkRK3Q$  ",
		"@type": "Drug",
		"name": "IMCIVREE (setmelanotide)",
	    "url": "https://urldefense.com/v3/__https://imcivree.com__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-AO57yHM$  ",
	    "description": "IMCIVREE is a prescription medicine used in adults and children 6 years of age and older with obesity due to Bardet-Biedl syndrome (BBS) to help them lose weight and keep the weight off.",
	    "activeIngredient": "setmelanotide",
	    "administrationRoute": "intravenous",
		"doseSchedule": {
	        "@type": "DoseSchedule",
	        "frequency": "IMCIVREE is a once-daily injection used to help reduce weight in people living with BBS"
	        },
	    "warning": "https://urldefense.com/v3/__https://rhythmtx.com/IMCIVREE/prescribing-information.pdf__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-6hG8KPg$  ",
	    "medicineSystem": "evidence-based",
	    "mechanismOfAction": "Setmelanotide is an MC4 receptor agonist with 20-fold less activity at the melanocortin 3 (MC3) and melanocortin 1 (MC1) receptors. MC4 receptors in the brain are involved in regulation of hunger, satiety, and energy expenditure.",
	    "recognizingAuthority": "FDA",
			"relevantSpecialty": [
				"endocrinology",
				"genetics",
				"ophthalmology",
				"nephrology"
					],
	    "prescribingInfo": "https://urldefense.com/v3/__https://rhythmtx.com/IMCIVREE/prescribing-information.pdf__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-6hG8KPg$  ",
	    "prescriptionStatus": "Prescription-Only Medication",
	    "image": {
	    	"@type": "ImageObject",
	    		"url":"https://urldefense.com/v3/__https://imcivree.com/images/imcivree-logo.svg__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-ifrG-Dg$  "
			}
	};

	let dataFive = { "@context": "https://urldefense.com/v3/__http://schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-m8FExX4$  ",
		 "@type": "MedicalIndication",
		 "name": "IMCIVREE (setmelanotide)",
		 "description": "IMCIVREE is indicated for chronic weight management in adult and pediatric patients 6 years of age and older with monogenic or syndromic obesity due to Bardet-Biedl syndrome (BBS)."
	};

	let dataSix = {
	  "@context": "https://urldefense.com/v3/__https://schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-pT-u95Y$  ",
	  "@type": "FAQPage",
	  "mainEntity": [{
	    "@type": "Question",
	    "name": "Do I need to take my IMCIVREE every day?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Yes, IMCIVREE should be injected once daily at the beginning of the day."
	    }
	  },{
	    "@type": "Question",
	    "name": "What time of day should I take IMCIVREE?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "IMCIVREE should be injected once daily, at the beginning of the day, and can be given at home, with or without food."
	    }
	  },{
	    "@type": "Question",
	    "name": "Why is it important to take IMCIVREE every day?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "IMCIVREE was studied and approved as a daily injection."
	    }
	  },{
	    "@type": "Question",
	    "name": "What should I do if I miss a dose?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "If a dose of IMCIVREE is missed, inject the next one at the regularly scheduled time the next day. Do not take the missed dose of IMCIVREE."
	    }
	  },{
	    "@type": "Question",
	    "name": "Do I have to take IMCIVREE for an entire year before I start to lose weight?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Please note that each person is different, and results can vary from one person to another. Please talk to your doctor about your own treatment expectations. In the yearlong clinical trial that studied IMCIVREE for weight reduction in people with obesity and a clinical diagnosis of BBS, people taking IMCIVREE started losing weight early on in treatment and continued to lose weight over the course of their treatment."
	    }
	  },{
	    "@type": "Question",
	    "name": "Will nausea and vomiting get better the longer I take IMCIVREE?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "We encourage you to reach out to your doctor for more information about nausea and vomiting. Responses vary, but reports of nausea and vomiting side effects in the clinical trials mostly occurred within the first month of treatment, and then sharply declined after 4 weeks. These side effects typically lasted a few days. In the yearlong clinical trial that studied IMCIVREE for weight reduction in people with obesity and a clinical diagnosis of BBS, nausea was reported by 26% of people taking IMCIVREE, and vomiting was reported by 19% of people taking IMCIVREE. It’s also important to know that most nausea or vomiting events in the clinical trials of IMCIVREE were mild and none were severe."
	    }
	  },{
	    "@type": "Question",
	    "name": "I have questions. Who should I contact?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Please reach out to your doctor if you have any questions about IMCIVREE. For questions or personalized support, please contact Rhythm InTune."
	    }
		},{
	    "@type": "Question",
	    "name": "How do I get more supplies if I’m running low?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "If you're low on supplies, contact Rhythm InTune to order more."
	    }
	  }]
	};

	return(
		<>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(dataOne)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataSix)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataTwo)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataThree)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataFour)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataFive)}
				</script>
			</Helmet>
		</>
	);
}

export default PatientBbsSd
