exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-bbs-a-closer-look-at-how-imcivree-works-in-the-brain-js": () => import("./../../../src/pages/bbs/a-closer-look-at-how-imcivree-works-in-the-brain.js" /* webpackChunkName: "component---src-pages-bbs-a-closer-look-at-how-imcivree-works-in-the-brain-js" */),
  "component---src-pages-bbs-accessibility-js": () => import("./../../../src/pages/bbs/accessibility.js" /* webpackChunkName: "component---src-pages-bbs-accessibility-js" */),
  "component---src-pages-bbs-ambars-journey-to-imcivree-js": () => import("./../../../src/pages/bbs/ambars-journey-to-imcivree.js" /* webpackChunkName: "component---src-pages-bbs-ambars-journey-to-imcivree-js" */),
  "component---src-pages-bbs-effect-of-imcivree-js": () => import("./../../../src/pages/bbs/effect-of-imcivree.js" /* webpackChunkName: "component---src-pages-bbs-effect-of-imcivree-js" */),
  "component---src-pages-bbs-faq-js": () => import("./../../../src/pages/bbs/faq.js" /* webpackChunkName: "component---src-pages-bbs-faq-js" */),
  "component---src-pages-bbs-getting-started-js": () => import("./../../../src/pages/bbs/getting-started.js" /* webpackChunkName: "component---src-pages-bbs-getting-started-js" */),
  "component---src-pages-bbs-hear-about-real-experiences-with-insatiable-hunger-js": () => import("./../../../src/pages/bbs/hear-about-real-experiences-with-insatiable-hunger.js" /* webpackChunkName: "component---src-pages-bbs-hear-about-real-experiences-with-insatiable-hunger-js" */),
  "component---src-pages-bbs-how-imcivree-given-es-js": () => import("./../../../src/pages/bbs/how-imcivree-given-es.js" /* webpackChunkName: "component---src-pages-bbs-how-imcivree-given-es-js" */),
  "component---src-pages-bbs-how-imcivree-given-js": () => import("./../../../src/pages/bbs/how-imcivree-given.js" /* webpackChunkName: "component---src-pages-bbs-how-imcivree-given-js" */),
  "component---src-pages-bbs-how-imcivree-works-js": () => import("./../../../src/pages/bbs/how-imcivree-works.js" /* webpackChunkName: "component---src-pages-bbs-how-imcivree-works-js" */),
  "component---src-pages-bbs-how-to-take-imcivree-es-js": () => import("./../../../src/pages/bbs/how-to-take-imcivree-es.js" /* webpackChunkName: "component---src-pages-bbs-how-to-take-imcivree-es-js" */),
  "component---src-pages-bbs-how-to-take-imcivree-js": () => import("./../../../src/pages/bbs/how-to-take-imcivree.js" /* webpackChunkName: "component---src-pages-bbs-how-to-take-imcivree-js" */),
  "component---src-pages-bbs-index-js": () => import("./../../../src/pages/bbs/index.js" /* webpackChunkName: "component---src-pages-bbs-index-js" */),
  "component---src-pages-bbs-learn-more-about-one-on-one-personalized-support-from-a-patient-education-manager-js": () => import("./../../../src/pages/bbs/learn-more-about-one-on-one-personalized-support-from-a-patient-education-manager.js" /* webpackChunkName: "component---src-pages-bbs-learn-more-about-one-on-one-personalized-support-from-a-patient-education-manager-js" */),
  "component---src-pages-bbs-managing-possible-side-effects-with-imcivree-js": () => import("./../../../src/pages/bbs/managing-possible-side-effects-with-imcivree.js" /* webpackChunkName: "component---src-pages-bbs-managing-possible-side-effects-with-imcivree-js" */),
  "component---src-pages-bbs-overview-js": () => import("./../../../src/pages/bbs/overview.js" /* webpackChunkName: "component---src-pages-bbs-overview-js" */),
  "component---src-pages-bbs-patient-access-js": () => import("./../../../src/pages/bbs/patient-access.js" /* webpackChunkName: "component---src-pages-bbs-patient-access-js" */),
  "component---src-pages-bbs-programs-js": () => import("./../../../src/pages/bbs/programs.js" /* webpackChunkName: "component---src-pages-bbs-programs-js" */),
  "component---src-pages-bbs-quality-of-life-results-js": () => import("./../../../src/pages/bbs/quality-of-life-results.js" /* webpackChunkName: "component---src-pages-bbs-quality-of-life-results-js" */),
  "component---src-pages-bbs-resources-js": () => import("./../../../src/pages/bbs/resources.js" /* webpackChunkName: "component---src-pages-bbs-resources-js" */),
  "component---src-pages-bbs-side-effects-js": () => import("./../../../src/pages/bbs/side-effects.js" /* webpackChunkName: "component---src-pages-bbs-side-effects-js" */),
  "component---src-pages-bbs-sign-up-js": () => import("./../../../src/pages/bbs/sign-up.js" /* webpackChunkName: "component---src-pages-bbs-sign-up-js" */),
  "component---src-pages-bbs-sitemap-js": () => import("./../../../src/pages/bbs/sitemap.js" /* webpackChunkName: "component---src-pages-bbs-sitemap-js" */),
  "component---src-pages-bbs-studied-js": () => import("./../../../src/pages/bbs/studied.js" /* webpackChunkName: "component---src-pages-bbs-studied-js" */),
  "component---src-pages-bbs-support-resources-js": () => import("./../../../src/pages/bbs/support-resources.js" /* webpackChunkName: "component---src-pages-bbs-support-resources-js" */),
  "component---src-pages-bbs-thankyou-js": () => import("./../../../src/pages/bbs/thankyou.js" /* webpackChunkName: "component---src-pages-bbs-thankyou-js" */),
  "component---src-pages-bbs-the-impact-of-imcivree-js": () => import("./../../../src/pages/bbs/the-impact-of-imcivree.js" /* webpackChunkName: "component---src-pages-bbs-the-impact-of-imcivree-js" */),
  "component---src-pages-bbs-understanding-your-dose-js": () => import("./../../../src/pages/bbs/understanding-your-dose.js" /* webpackChunkName: "component---src-pages-bbs-understanding-your-dose-js" */),
  "component---src-pages-hcp-bbs-accessibility-js": () => import("./../../../src/pages/hcp/bbs/accessibility.js" /* webpackChunkName: "component---src-pages-hcp-bbs-accessibility-js" */),
  "component---src-pages-hcp-bbs-adult-weight-reduction-js": () => import("./../../../src/pages/hcp/bbs/adult-weight-reduction.js" /* webpackChunkName: "component---src-pages-hcp-bbs-adult-weight-reduction-js" */),
  "component---src-pages-hcp-bbs-diagnosing-bbs-in-your-practice-js": () => import("./../../../src/pages/hcp/bbs/diagnosing-bbs-in-your-practice.js" /* webpackChunkName: "component---src-pages-hcp-bbs-diagnosing-bbs-in-your-practice-js" */),
  "component---src-pages-hcp-bbs-diagnosis-js": () => import("./../../../src/pages/hcp/bbs/diagnosis.js" /* webpackChunkName: "component---src-pages-hcp-bbs-diagnosis-js" */),
  "component---src-pages-hcp-bbs-dosing-administration-js": () => import("./../../../src/pages/hcp/bbs/dosing-administration.js" /* webpackChunkName: "component---src-pages-hcp-bbs-dosing-administration-js" */),
  "component---src-pages-hcp-bbs-faq-js": () => import("./../../../src/pages/hcp/bbs/faq.js" /* webpackChunkName: "component---src-pages-hcp-bbs-faq-js" */),
  "component---src-pages-hcp-bbs-hunger-reduction-js": () => import("./../../../src/pages/hcp/bbs/hunger-reduction.js" /* webpackChunkName: "component---src-pages-hcp-bbs-hunger-reduction-js" */),
  "component---src-pages-hcp-bbs-hyperphagia-js": () => import("./../../../src/pages/hcp/bbs/hyperphagia.js" /* webpackChunkName: "component---src-pages-hcp-bbs-hyperphagia-js" */),
  "component---src-pages-hcp-bbs-index-js": () => import("./../../../src/pages/hcp/bbs/index.js" /* webpackChunkName: "component---src-pages-hcp-bbs-index-js" */),
  "component---src-pages-hcp-bbs-learn-more-about-patient-education-managers-js": () => import("./../../../src/pages/hcp/bbs/learn-more-about-patient-education-managers.js" /* webpackChunkName: "component---src-pages-hcp-bbs-learn-more-about-patient-education-managers-js" */),
  "component---src-pages-hcp-bbs-managing-adverse-events-and-injection-training-js": () => import("./../../../src/pages/hcp/bbs/managing-adverse-events-and-injection-training.js" /* webpackChunkName: "component---src-pages-hcp-bbs-managing-adverse-events-and-injection-training-js" */),
  "component---src-pages-hcp-bbs-moa-js": () => import("./../../../src/pages/hcp/bbs/moa.js" /* webpackChunkName: "component---src-pages-hcp-bbs-moa-js" */),
  "component---src-pages-hcp-bbs-overview-js": () => import("./../../../src/pages/hcp/bbs/overview.js" /* webpackChunkName: "component---src-pages-hcp-bbs-overview-js" */),
  "component---src-pages-hcp-bbs-patient-support-js": () => import("./../../../src/pages/hcp/bbs/patient-support.js" /* webpackChunkName: "component---src-pages-hcp-bbs-patient-support-js" */),
  "component---src-pages-hcp-bbs-pediatric-bmi-z-score-reduction-js": () => import("./../../../src/pages/hcp/bbs/pediatric-bmi-z-score-reduction.js" /* webpackChunkName: "component---src-pages-hcp-bbs-pediatric-bmi-z-score-reduction-js" */),
  "component---src-pages-hcp-bbs-prescribing-imcivree-js": () => import("./../../../src/pages/hcp/bbs/prescribing-imcivree.js" /* webpackChunkName: "component---src-pages-hcp-bbs-prescribing-imcivree-js" */),
  "component---src-pages-hcp-bbs-quality-of-life-js": () => import("./../../../src/pages/hcp/bbs/quality-of-life.js" /* webpackChunkName: "component---src-pages-hcp-bbs-quality-of-life-js" */),
  "component---src-pages-hcp-bbs-real-experiences-with-hyperphagia-js": () => import("./../../../src/pages/hcp/bbs/real-experiences-with-hyperphagia.js" /* webpackChunkName: "component---src-pages-hcp-bbs-real-experiences-with-hyperphagia-js" */),
  "component---src-pages-hcp-bbs-safety-profile-js": () => import("./../../../src/pages/hcp/bbs/safety-profile.js" /* webpackChunkName: "component---src-pages-hcp-bbs-safety-profile-js" */),
  "component---src-pages-hcp-bbs-sign-up-js": () => import("./../../../src/pages/hcp/bbs/sign-up.js" /* webpackChunkName: "component---src-pages-hcp-bbs-sign-up-js" */),
  "component---src-pages-hcp-bbs-sitemap-js": () => import("./../../../src/pages/hcp/bbs/sitemap.js" /* webpackChunkName: "component---src-pages-hcp-bbs-sitemap-js" */),
  "component---src-pages-hcp-bbs-study-design-js": () => import("./../../../src/pages/hcp/bbs/study-design.js" /* webpackChunkName: "component---src-pages-hcp-bbs-study-design-js" */),
  "component---src-pages-hcp-bbs-thankyou-js": () => import("./../../../src/pages/hcp/bbs/thankyou.js" /* webpackChunkName: "component---src-pages-hcp-bbs-thankyou-js" */),
  "component---src-pages-hcp-bbs-the-impact-of-imcivree-js": () => import("./../../../src/pages/hcp/bbs/the-impact-of-imcivree.js" /* webpackChunkName: "component---src-pages-hcp-bbs-the-impact-of-imcivree-js" */),
  "component---src-pages-hcp-bbs-types-of-obesity-js": () => import("./../../../src/pages/hcp/bbs/types-of-obesity.js" /* webpackChunkName: "component---src-pages-hcp-bbs-types-of-obesity-js" */),
  "component---src-pages-hcp-bbs-webinars-downloadable-resources-js": () => import("./../../../src/pages/hcp/bbs/webinars-downloadable-resources.js" /* webpackChunkName: "component---src-pages-hcp-bbs-webinars-downloadable-resources-js" */),
  "component---src-pages-hcp-index-js": () => import("./../../../src/pages/hcp/index.js" /* webpackChunkName: "component---src-pages-hcp-index-js" */),
  "component---src-pages-hcp-ppl-accessibility-js": () => import("./../../../src/pages/hcp/ppl/accessibility.js" /* webpackChunkName: "component---src-pages-hcp-ppl-accessibility-js" */),
  "component---src-pages-hcp-ppl-dosing-administration-js": () => import("./../../../src/pages/hcp/ppl/dosing-administration.js" /* webpackChunkName: "component---src-pages-hcp-ppl-dosing-administration-js" */),
  "component---src-pages-hcp-ppl-faq-js": () => import("./../../../src/pages/hcp/ppl/faq.js" /* webpackChunkName: "component---src-pages-hcp-ppl-faq-js" */),
  "component---src-pages-hcp-ppl-hunger-reduction-js": () => import("./../../../src/pages/hcp/ppl/hunger-reduction.js" /* webpackChunkName: "component---src-pages-hcp-ppl-hunger-reduction-js" */),
  "component---src-pages-hcp-ppl-identifying-hyperphagia-js": () => import("./../../../src/pages/hcp/ppl/identifying-hyperphagia.js" /* webpackChunkName: "component---src-pages-hcp-ppl-identifying-hyperphagia-js" */),
  "component---src-pages-hcp-ppl-index-js": () => import("./../../../src/pages/hcp/ppl/index.js" /* webpackChunkName: "component---src-pages-hcp-ppl-index-js" */),
  "component---src-pages-hcp-ppl-injection-training-es-js": () => import("./../../../src/pages/hcp/ppl/injection-training-es.js" /* webpackChunkName: "component---src-pages-hcp-ppl-injection-training-es-js" */),
  "component---src-pages-hcp-ppl-learn-more-about-patient-education-managers-js": () => import("./../../../src/pages/hcp/ppl/learn-more-about-patient-education-managers.js" /* webpackChunkName: "component---src-pages-hcp-ppl-learn-more-about-patient-education-managers-js" */),
  "component---src-pages-hcp-ppl-managing-adverse-events-and-injection-training-js": () => import("./../../../src/pages/hcp/ppl/managing-adverse-events-and-injection-training.js" /* webpackChunkName: "component---src-pages-hcp-ppl-managing-adverse-events-and-injection-training-js" */),
  "component---src-pages-hcp-ppl-moa-js": () => import("./../../../src/pages/hcp/ppl/moa.js" /* webpackChunkName: "component---src-pages-hcp-ppl-moa-js" */),
  "component---src-pages-hcp-ppl-overview-js": () => import("./../../../src/pages/hcp/ppl/overview.js" /* webpackChunkName: "component---src-pages-hcp-ppl-overview-js" */),
  "component---src-pages-hcp-ppl-patient-resources-js": () => import("./../../../src/pages/hcp/ppl/patient-resources.js" /* webpackChunkName: "component---src-pages-hcp-ppl-patient-resources-js" */),
  "component---src-pages-hcp-ppl-patient-support-js": () => import("./../../../src/pages/hcp/ppl/patient-support.js" /* webpackChunkName: "component---src-pages-hcp-ppl-patient-support-js" */),
  "component---src-pages-hcp-ppl-prescribing-imcivree-js": () => import("./../../../src/pages/hcp/ppl/prescribing-imcivree.js" /* webpackChunkName: "component---src-pages-hcp-ppl-prescribing-imcivree-js" */),
  "component---src-pages-hcp-ppl-real-experiences-with-hyperphagia-js": () => import("./../../../src/pages/hcp/ppl/real-experiences-with-hyperphagia.js" /* webpackChunkName: "component---src-pages-hcp-ppl-real-experiences-with-hyperphagia-js" */),
  "component---src-pages-hcp-ppl-safety-profile-js": () => import("./../../../src/pages/hcp/ppl/safety-profile.js" /* webpackChunkName: "component---src-pages-hcp-ppl-safety-profile-js" */),
  "component---src-pages-hcp-ppl-sign-up-js": () => import("./../../../src/pages/hcp/ppl/sign-up.js" /* webpackChunkName: "component---src-pages-hcp-ppl-sign-up-js" */),
  "component---src-pages-hcp-ppl-sitemap-js": () => import("./../../../src/pages/hcp/ppl/sitemap.js" /* webpackChunkName: "component---src-pages-hcp-ppl-sitemap-js" */),
  "component---src-pages-hcp-ppl-study-design-js": () => import("./../../../src/pages/hcp/ppl/study-design.js" /* webpackChunkName: "component---src-pages-hcp-ppl-study-design-js" */),
  "component---src-pages-hcp-ppl-taking-imcivree-js": () => import("./../../../src/pages/hcp/ppl/taking-imcivree.js" /* webpackChunkName: "component---src-pages-hcp-ppl-taking-imcivree-js" */),
  "component---src-pages-hcp-ppl-thankyou-js": () => import("./../../../src/pages/hcp/ppl/thankyou.js" /* webpackChunkName: "component---src-pages-hcp-ppl-thankyou-js" */),
  "component---src-pages-hcp-ppl-types-of-obesity-js": () => import("./../../../src/pages/hcp/ppl/types-of-obesity.js" /* webpackChunkName: "component---src-pages-hcp-ppl-types-of-obesity-js" */),
  "component---src-pages-hcp-ppl-weight-reduction-js": () => import("./../../../src/pages/hcp/ppl/weight-reduction.js" /* webpackChunkName: "component---src-pages-hcp-ppl-weight-reduction-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ppl-accessibility-js": () => import("./../../../src/pages/ppl/accessibility.js" /* webpackChunkName: "component---src-pages-ppl-accessibility-js" */),
  "component---src-pages-ppl-clinical-study-results-js": () => import("./../../../src/pages/ppl/clinical-study-results.js" /* webpackChunkName: "component---src-pages-ppl-clinical-study-results-js" */),
  "component---src-pages-ppl-disease-overview-js": () => import("./../../../src/pages/ppl/disease-overview.js" /* webpackChunkName: "component---src-pages-ppl-disease-overview-js" */),
  "component---src-pages-ppl-effect-of-imcivree-js": () => import("./../../../src/pages/ppl/effect-of-imcivree.js" /* webpackChunkName: "component---src-pages-ppl-effect-of-imcivree-js" */),
  "component---src-pages-ppl-faq-js": () => import("./../../../src/pages/ppl/faq.js" /* webpackChunkName: "component---src-pages-ppl-faq-js" */),
  "component---src-pages-ppl-getting-started-js": () => import("./../../../src/pages/ppl/getting-started.js" /* webpackChunkName: "component---src-pages-ppl-getting-started-js" */),
  "component---src-pages-ppl-helpful-resources-js": () => import("./../../../src/pages/ppl/helpful-resources.js" /* webpackChunkName: "component---src-pages-ppl-helpful-resources-js" */),
  "component---src-pages-ppl-how-imcivree-is-given-js": () => import("./../../../src/pages/ppl/how-imcivree-is-given.js" /* webpackChunkName: "component---src-pages-ppl-how-imcivree-is-given-js" */),
  "component---src-pages-ppl-how-it-works-js": () => import("./../../../src/pages/ppl/how-it-works.js" /* webpackChunkName: "component---src-pages-ppl-how-it-works-js" */),
  "component---src-pages-ppl-index-js": () => import("./../../../src/pages/ppl/index.js" /* webpackChunkName: "component---src-pages-ppl-index-js" */),
  "component---src-pages-ppl-injection-training-es-js": () => import("./../../../src/pages/ppl/injection-training-es.js" /* webpackChunkName: "component---src-pages-ppl-injection-training-es-js" */),
  "component---src-pages-ppl-injection-training-js": () => import("./../../../src/pages/ppl/injection-training.js" /* webpackChunkName: "component---src-pages-ppl-injection-training-js" */),
  "component---src-pages-ppl-patient-access-js": () => import("./../../../src/pages/ppl/patient-access.js" /* webpackChunkName: "component---src-pages-ppl-patient-access-js" */),
  "component---src-pages-ppl-rhythm-intune-js": () => import("./../../../src/pages/ppl/rhythm-intune.js" /* webpackChunkName: "component---src-pages-ppl-rhythm-intune-js" */),
  "component---src-pages-ppl-side-effects-js": () => import("./../../../src/pages/ppl/side-effects.js" /* webpackChunkName: "component---src-pages-ppl-side-effects-js" */),
  "component---src-pages-ppl-sign-up-js": () => import("./../../../src/pages/ppl/sign-up.js" /* webpackChunkName: "component---src-pages-ppl-sign-up-js" */),
  "component---src-pages-ppl-sitemap-js": () => import("./../../../src/pages/ppl/sitemap.js" /* webpackChunkName: "component---src-pages-ppl-sitemap-js" */),
  "component---src-pages-ppl-taking-imcivree-js": () => import("./../../../src/pages/ppl/taking-imcivree.js" /* webpackChunkName: "component---src-pages-ppl-taking-imcivree-js" */),
  "component---src-pages-ppl-thankyou-js": () => import("./../../../src/pages/ppl/thankyou.js" /* webpackChunkName: "component---src-pages-ppl-thankyou-js" */),
  "component---src-pages-ppl-understanding-your-dose-js": () => import("./../../../src/pages/ppl/understanding-your-dose.js" /* webpackChunkName: "component---src-pages-ppl-understanding-your-dose-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-taking-imcivree-js": () => import("./../../../src/pages/taking-imcivree.js" /* webpackChunkName: "component---src-pages-taking-imcivree-js" */)
}

