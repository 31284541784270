import React from 'react'
import Helmet from "react-helmet";

const HcpPplSd =()=>{

	let dataOne = { "@context": "https://urldefense.com/v3/__http://www.schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-IwkRK3Q$  ",
		"@type": "MedicalWebPage",
		"description": "IMCIVREE is the FIRST and ONLY treatment to target impairment in the MC4R pathway, a root cause of hyperphagia and obesity in patients living with POMC, PCSK1, or LEPR deficiency.",
		"sourceOrganization":{
			"@type": "Corporation",
			"name": "Rhythm Pharmaceuticals",
			"url": "https://urldefense.com/v3/__https://www.rhythmtx.com/__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-Re6XJxA$  ",
			"tickerSymbol": "RHTYM",
	        "legalName": "Rhythm Pharmaceuticals, Inc.",
			"logo": {
	          	"@type": "ImageObject",
	          	"url": "https://urldefense.com/v3/__https://www.rhythmtx.com/wp-content/uploads/2020/11/Rhythm_Logo_CMYK_EPS-registration-mark-1.svg__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-zP3R6Fc$  "
	         	 },
		  "telephone": "833-789-6337",
	       "address": {
	    		"@type": "PostalAddress",
	    		"addressCountry": "USA",
			    "addressLocality": "Boston",
	    		"addressRegion": "Massachusetts",
	    		"postalCode": "02116",
	    		"streetAddress": "222 Berkeley Street, 12th Floor"
				},
	          "sameAs": [
	          	"https://urldefense.com/v3/__https://www.linkedin.com/company/rhythm-pharmaceuticals-inc-/__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-ci7RpGg$  ",
	          	"https://urldefense.com/v3/__https://twitter.com/rhythmpharma__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-wx13mk4$  ",
	          	"https://urldefense.com/v3/__https://finance.yahoo.com/quote/RYTM__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-qOMuWRM$  ",
	          	"https://urldefense.com/v3/__https://www.bloomberg.com/profile/company/RYTM:US__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp--o-t3ZU$  ",
	          	"https://urldefense.com/v3/__https://www.reuters.com/markets/companies/RYTM.OQ/__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-_DYrDbk$  "
	         	 ],
	          "description": "Rhythm is dedicated to understanding rare genetic diseases of obesity caused by impaired signaling in the central pathway of the brain known as the melanocortin-4 receptor (MC4R) pathway.",
			"Brand": {
				"@type": "Brand",
				"name": "IMCIVREE",
				"url": "https://urldefense.com/v3/__https://imcivree.com__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-AO57yHM$  ",
				"logo": "https://urldefense.com/v3/__https://imcivree.com/images/imcivree-logo.svg__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-ifrG-Dg$  "
			}
		}
	}

	let dataTwo = {
		"@context": "https://urldefense.com/v3/__http://www.schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-IwkRK3Q$  ",
		"@type": "MedicalAudience",
		"name": "Healthcare Professional",
		"url": "https://urldefense.com/v3/__https://imcivree.com__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-AO57yHM$  ",
		"geographicArea":{
			"@type": "AdministrativeArea",
			"name": "This site is intended for US residents only.",
			"url": "https://urldefense.com/v3/__https://imcivree.com__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-AO57yHM$  "
		}
	}

	let dataThree = {
		"@context": "https://urldefense.com/v3/__http://schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-m8FExX4$  ",
		"@type": "MedicalCondition",
		  "name": [
		    "POMC Deficiency",
		    "PCSK1 Deficiency",
			"LEPR Deficiency"
		  ],
		"signOrSymptom": [
			"obesity",
			"hyperphagia"
		]
	}

	let dataFour = {
		"@context": "https://urldefense.com/v3/__http://www.schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-IwkRK3Q$  ",
		"@type": "Drug",
		"name": "IMCIVREE (setmelanotide)",
	    "url": "https://urldefense.com/v3/__https://imcivree.com__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-AO57yHM$  ",
	    "description": "IMCIVREE is the FIRST and ONLY treatment to target impairment in the MC4R pathway, a root cause of hyperphagia and obesity in patients living with POMC, PCSK1, or LEPR deficiency.",
	    "activeIngredient": "setmelanotide",
	    "administrationRoute": "intravenous",
		"doseSchedule": {
	        "@type": "DoseSchedule",
	        "frequency": "IMCIVREE is a once-daily injection used to help reduce weight in people living with POMC, PCSK1, or LEPR deficiency. IMCIVREE has helped people 6 years of age and older lose weight and keep it off."
	        },
	    "warning": "https://urldefense.com/v3/__https://rhythmtx.com/IMCIVREE/prescribing-information.pdf__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-6hG8KPg$  ",
	    "medicineSystem": "evidence-based",
	    "mechanismOfAction": "Setmelanotide is an MC4 receptor agonist with 20-fold less activity at the melanocortin 3 (MC3) and melanocortin 1 (MC1) receptors. MC4 receptors in the brain are involved in regulation of hunger, satiety, and energy expenditure. Based on nonclinical evidence, setmelanotide may re-establish MC4 receptor pathway activity to reduce food intake and promote weight loss through decreased caloric intake and increased energy expenditure in patients with obesity due to POMC, PCSK1, or LEPR deficiency, or BBS associated with insufficient activation of the MC4 receptor. The MC1 receptor is expressed on melanocytes, and activation of this receptor leads to accumulation of melanin and increased skin pigmentation independently of ultraviolet light.",
	    "recognizingAuthority": "FDA",
			"relevantSpecialty": [
				"endocrinology",
				"genetics",
				"ophthalmology",
				"nephrology"
					],
	    "prescribingInfo": "https://urldefense.com/v3/__https://rhythmtx.com/IMCIVREE/prescribing-information.pdf__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-6hG8KPg$  ",
	    "prescriptionStatus": "Prescription-Only Medication",
	    "image": {
	    	"@type": "ImageObject",
	    		"url":"https://urldefense.com/v3/__https://imcivree.com/images/imcivree-logo.svg__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-ifrG-Dg$  "
			}
	}

	let dataFive = {
		 "@context": "https://urldefense.com/v3/__http://schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-m8FExX4$  ",
		 "@type": "MedicalIndication",
		 "name": "IMCIVREE (setmelanotide)",
		 "description": "IMCIVREE is indicated for chronic weight management in adult and pediatric patients 6 years of age and older with monogenic or syndromic obesity due to pro-opiomelanocortin (POMC), proprotein convertase subtilisin/kexin type 1 (PCSK1), or leptin receptor (LEPR) deficiency as determined by an FDA-approved test demonstrating variants in POMC, PCSK1, or LEPR genes that are interpreted as pathogenic, likely pathogenic, or of uncertain significance (VUS)."
	}

	let dataSix = {
	  "@context": "https://urldefense.com/v3/__https://schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-pT-u95Y$  ",
	  "@type": "FAQPage",
	  "mainEntity": [{
	    "@type": "Question",
	    "name": "What is IMCIVREE indicated for?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "IMCIVREE is indicated for chronic weight management in adult and pediatric patients 6 years of age and older with monogenic or syndromic obesity due to: Pro-opiomelanocortin (POMC), proprotein convertase subtilisin/kexin type 1 (PCSK1), or leptin receptor (LEPR) deficiency as determined by an FDA-approved test demonstrating variants in POMC, PCSK1, or LEPR genes that are interpreted as pathogenic, likely pathogenic, or of uncertain significance (VUS). Bardet-Biedl syndrome (BBS). IMCIVREE is not indicated for the treatment of patients with the following conditions as IMCIVREE would not be expected to be effective: Obesity due to suspected POMC, PCSK1, or LEPR deficiency with POMC, PCSK1, or LEPR variants classified as benign or likely benign. Other types of obesity not related to POMC, PCSK1, or LEPR deficiency or BBS, including obesity associated with other genetic syndromes and general (polygenic) obesity. Rhythm’s companion diagnostic device (or CDx) is approved by the FDA and is used to identify people who are appropriate for treatment with IMCIVREE based on a genetic confirmation of POMC, PCSK1, or LEPR deficiency. For more information on this FDA-approved genetic test, please visit rhythm.preventiongenetics.com."
	    }
	  },{
	    "@type": "Question",
	    "name": "Are there any contraindications to IMCIVREE?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "No. There are no contraindications to IMCIVREE.<sup>1</sup>"
	    }
	  },{
	    "@type": "Question",
	    "name": "How do I acquire a genetic test for my patients?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "IMCIVREE is indicated for chronic weight management in patients 6 years of age and older with obesity due to POMC, PCSK1, or LEPR deficiency confirmed by an FDA-approved test. These diseases are biallelic, signifying that variants are present in both copies of the gene, and therefore include both homozygous and compound heterozygous forms. Rhythm’s companion diagnostic device (or CDx) is approved by the FDA and is used to identify people who are appropriate for treatment with IMCIVREE based on a genetic confirmation of POMC, PCSK1, or LEPR deficiency. For more information about acquiring this FDA-approved genetic test, please visit rhythm.preventiongenetics.com. Rhythm Pharmaceuticals offers no-cost genetic testing for rare genetic diseases of obesity to eligible patients. Rhythm Pharmaceuticals covers the cost of the test, with the patient responsible for any office visit, sample collection, or related elements. For additional information, call PreventionGenetics at 1-844-513-3994, Monday—Friday, 9 AM to 8 PM ET."
	    }
	  },{
	    "@type": "Question",
	    "name": "Do I have to submit the results of genetic testing with the Start Form?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Yes, you must send your patient’s genetic test results with the IMCIVREE Start Form. Rhythm Pharmaceuticals offers no-cost genetic testing for rare genetic diseases of obesity to eligible patients. Rhythm Pharmaceuticals covers the cost of the test, with the patient responsible for any office visit, sample collection, or related elements. Additional information is available at https://urldefense.com/v3/__http://www.uncoveringrareobesity.com__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-x0YME_U$   or by calling PreventionGenetics at 1-844-513-3994, Monday—Friday, 9 AM to 8 PM ET."
	    }
	  },{
	    "@type": "Question",
	    "name": "How is IMCIVREE administered?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "IMCIVREE is administered by subcutaneous injection, once daily, at the beginning of each day without regard to meals. The process for subcutaneous injection is described in the Instructions for Use, which contains complete administration instructions with illustrations."
	    }
	  },{
	    "@type": "Question",
	    "name": "What part of the body should patients inject IMCIVREE into?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "IMCIVREE should be injected subcutaneously in the abdomen, thigh, or arm, rotating to a different site each day. (Refer to Step 5 of the Instructions for Use.) Do not administer IMCIVREE intravenously or intramuscularly."
	    }
	  },{
	    "@type": "Question",
	    "name": "What type of syringe should the patient use to administer IMCIVREE?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Prior to initiation of IMCIVREE, train patients or their caregivers on proper injection technique. Instruct patients to use a 1-mL syringe with a 28- or 29-gauge needle appropriate for subcutaneous injection."
	    }
		},{
	    "@type": "Question",
	    "name": "My patient missed a dose. Does he/she need to retitrate their dose?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "If a dose is missed, the once-daily regimen should be resumed as prescribed with the next scheduled dose."
	    }
		},{
	    "@type": "Question",
	    "name": "My patient discontinued treatment for an extended period of time. Do I need to retitrate the dose?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "No. Dose titration is only recommended to initially assess tolerability to the medication. In clinical studies, patients were placed on placebo for a 4-week period, then reinitiated therapy at their therapeutic dose for the remainder of the study."
	    }
		},{
	    "@type": "Question",
	    "name": "My patient accidentally administered an overdose of IMCIVREE. What adverse events should we be most concerned about?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "In the event of an overdose, initiate appropriate supportive treatment according to the patient’s clinical signs and symptoms."
	    }
		},{
	    "@type": "Question",
	    "name": "What were the most common adverse events observed in the clinical trials?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "The most common adverse reactions (incidence ≥20%) included skin hyperpigmentation, injection site reactions, nausea, headache, diarrhea, abdominal pain, vomiting, depression, and spontaneous penile erection. Injection site reactions, nausea, and vomiting tended to occur within the first month after starting treatment, and then diminished over time. Changes in skin pigmentation/hair color are primarily reported within the first month of treatment, then become more gradual or stabilize afterward."
	    }
		},{
	    "@type": "Question",
	    "name": "What is hyperpigmentation? Is it just skin darkening?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Hyperpigmentation can involve the darkening of skin and pre-existing nevi in the absence of ultraviolet (UV) exposure."
	    }
		},{
	    "@type": "Question",
	    "name": "Is skin darkening reversible?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Generalized increased skin pigmentation occurred in the majority of patients (69%) treated with IMCIVREE in clinical trials. IMCIVREE may also cause darkening of pre-existing nevi due to its pharmacologic effect. This effect is reversible upon discontinuation of the drug."
	    }
		},{
	    "@type": "Question",
	    "name": "Why does my patient need a skin examination before starting IMCIVREE?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "IMCIVREE is an MC4 receptor agonist with some activity at the melanocortin-1 (MC1) receptors; activation of MC1 leads to accumulation of melanin and increased skin pigmentation. Hyperpigmentation was reversible upon discontinuation of IMCIVREE in clinical trials. Perform a full body skin examination prior to initiation and periodically during treatment with IMCIVREE to monitor pre-existing and new skin pigmentary lesions."
	    }
		},{
	    "@type": "Question",
	    "name": "Can IMCIVREE cause skin cancer?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "There were no reports of melanoma related to the observed hyperpigmentation in clinical trials of IMCIVREE."
	    }
		},{
	    "@type": "Question",
	    "name": "Does IMCIVREE cause or worsen depression?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Patients with a history of severe depression were excluded from the clinical trials. Some drugs that target the central nervous system, such as IMCIVREE, may cause depression or suicidal ideation. Depression (26%) and suicidal ideation (11%) occurred in adults and pediatric patients in IMCIVREE clinical studies. Patients with a history of depression or suicidal ideation may be at increased risk for recurrent episodes while taking IMCIVREE. Monitor patients for new onset or worsening of depression, suicidal thoughts or behavior, or any unusual changes in mood or behavior. Consider discontinuing IMCIVREE if patients experience suicidal thoughts or behaviors or if clinically significant or persistent depression symptoms occur."
	    }
		},{
	    "@type": "Question",
	    "name": "Can IMCIVREE be used with antidepressants?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "No clinical studies evaluating the drug-drug interaction potential of IMCIVREE have been conducted."
	    }
		},{
	    "@type": "Question",
	    "name": "Can IMCIVREE cause prolonged erections?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Spontaneous penile erections in males (24%) occurred in clinical trials with IMCIVREE. Inform patients that these events may occur and instruct patients who have an erection lasting longer than 4 hours to seek emergency medical attention."
	    }
		},{
	    "@type": "Question",
	    "name": "What sexual disturbances were reported in females in the clinical trials?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Sexual adverse reactions in females (7% in IMCIVREE-treated patients and 0% in placebo-treated patients from an unapproved population) occurred in clinical studies with IMCIVREE."
	    }
		},{
	    "@type": "Question",
	    "name": "What should a patient do if they think they are or may become pregnant?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Discontinue IMCIVREE when pregnancy is recognized unless the benefits of therapy outweigh the potential risks to the fetus."
	    }
		},{
	    "@type": "Question",
	    "name": "Can patients take IMCIVREE while breastfeeding? Does IMCIVREE enter the breast milk?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "There is no information on the presence of IMCIVREE or its metabolites in human milk, the effects on the breastfed infant, or the effects on milk production. Treatment with IMCIVREE is not recommended while breastfeeding. IMCIVREE from multiple-dose vials contains the preservative benzyl alcohol. Because benzyl alcohol is rapidly metabolized by a lactating woman, benzyl alcohol exposure in the breastfed infant is unlikely. However, adverse reactions have occurred in premature neonates and low birth weight infants who received intravenously administered benzyl alcohol-containing drugs."
	    }
		},{
	    "@type": "Question",
	    "name": "My patient missed a dose. Does he/she need to retitrate their dose?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "If a dose is missed, the once-daily regimen should be resumed as prescribed with the next scheduled dose."
	    }
		},{
	    "@type": "Question",
	    "name": "What is the mechanism of action?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "IMCIVREE is an MC4 receptor agonist with 20-fold less activity at the melanocortin-3 (MC3) and melanocortin-1 (MC1) receptors. MC4 receptors in the brain are involved in regulation of hunger, satiety, and energy expenditure. Based on nonclinical evidence, IMCIVREE may re-establish MC4 receptor pathway activity to reduce food intake and promote weight loss through decreased caloric intake and increased energy expenditure in patients with obesity due to POMC, PCSK1, or LEPR deficiency, or BBS associated with insufficient activation of the MC4 receptor. The MC1 receptor is expressed on melanocytes, and activation of this receptor leads to accumulation of melanin and increased skin pigmentation independently of ultraviolet light."
	    }
		},{
	    "@type": "Question",
	    "name": "Have the pivotal studies on IMCIVREE been published?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Yes. The results of these studies have been published in The Lancet Diabetes & Endocrinology."
	    }
		},{
	    "@type": "Question",
	    "name": "How were the safety and effectiveness of IMCIVREE evaluated?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "The safety and efficacy of IMCIVREE for the treatment of POMC, PCSK1, or LEPR deficiency obesity were established in 2 identically designed, 1-year, open-label studies, each with an 8-week, double-blind withdrawal period."
	    }
		},{
	    "@type": "Question",
	    "name": "Were the patients who did not achieve at least a 5-kg weight loss (or at least 5% weight loss if baseline body weight was <100 kg) at the end of the open-label treatment period included in any analyses? If so, which ones?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Yes. These patients were included in the primary endpoint analysis and safety analyses."
	    }
		},{
	    "@type": "Question",
	    "name": "Did patients regain weight after stopping IMCIVREE?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Yes. When treatment with IMCIVREE was withdrawn in patients who had lost weight during the 10-week open-label period, these patients gained an average of 5.5 kg in Study 1 and 5.0 kg in Study 2 over 4 weeks. Reinitiation of treatment with IMCIVREE resulted in subsequent weight loss."
	    }
		},{
	    "@type": "Question",
	    "name": "How do I prescribe IMCIVREE for my patient(s)?",
	    "acceptedAnswer": {
	      "@type": "Answer",
	      "text": "Rhythm’s companion diagnostic device (or CDx) is approved by the FDA and is used to identify people who are appropriate for treatment with IMCIVREE based on a genetic confirmation of POMC, PCSK1, or LEPR deficiency.  For more information about acquiring this FDA-approved genetic test, please visit rhythm.preventiongenetics.com.To prescribe IMCIVREE, a completed Start Form, including a copy of the patient’s genetic test results, will need to be faxed to 1-877-805-0130 or emailed to patientsupport@rhythmtx.com. A Start Form also enrolls your patient in the Rhythm InTune program, which will provide your patient with a number of resources, including help understanding their insurance, financial assistance, and educational services. Additionally, Rhythm InTune will coordinate the delivery of IMCIVREE, and provide support in learning how to inject IMCIVREE."
	    }
	  }]
	}

	return (
		<>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(dataOne)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataSix)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataTwo)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataThree)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataFour)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataFive)}
				</script>
			</Helmet>
		</>
	);
}

export default HcpPplSd
