import React, { useState, useEffect } from "react"
import Cookies from 'js-cookie';
import MainLogoSvg from "../../../images/imcivree-logo.svg"

const Interstitial = () => {
  const [interstitialConsent, setInterstitialConsent] = useState(false);
  const interstitialName = "intertitialDismiss";

  const acceptIntertitial = (e) => {
    e.preventDefault();
    Cookies.set(interstitialName, true, { expires: 1 });
    setInterstitialConsent(true);
  };

  useEffect(() => {
    let cb = document.getElementById("interstitial");
    setTimeout(() => {
      if (Cookies.get(interstitialName)) {
        setInterstitialConsent(true);
        if (cb) {
          cb.classList.remove("isVisible");
        }
      } else {
        setInterstitialConsent(false);
        if (cb) {
          cb.classList.add("isVisible");
        }
      }
    }, 100);
  }, []);

  return (
    <>
      {!interstitialConsent && (
        <div id="interstitial" onClick={(e) => acceptIntertitial(e)}>
          <div className="interstitial-container">
            <div className="interstitial-content">
              <img
                src={MainLogoSvg}
                alt="Imcivree Logo"
                width={219}
              />
              <h1>NOW APPROVED</h1>
              <h2>FOR CHILDREN 2 to &lt;6 YEARS OLD</h2>
              <h3>
                with obesity due to Bardet-Biedl syndrome (BBS) or POMC*, PCSK1*,
                or LEPR* deficiency.
              </h3>
              <p>
                <strong>
                  Please see Important Safety Information below, including full{" "}
                  <a
                    href="https://rhythmtx.com/IMCIVREE/prescribing-information.pdf"
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Prescribing Information
                  </a>
                </strong>
              </p>
              <p>
                <span>
                  *As confirmed by an FDA-approved genetic test<br></br>
                  POMC=proopiomelanocortin; PCSK1=proprotein convertase
                  subtilisin/kexin type 1; LEPR=leptin receptor.
                </span>
              </p>
            </div>
            <div className="close-btn">
              <button onClick={(e) => acceptIntertitial(e)}>
                <svg
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M31.1451 5.44062L31.4987 5.08706L31.1451 4.73351L28.2664 1.85476L27.9128 1.50121L27.5593 1.85476L16.4999 12.9141L5.44056 1.85476L5.087 1.50121L4.73345 1.85476L1.8547 4.73351L1.50115 5.08706L1.8547 5.44062L12.9141 16.5L1.8547 27.5593L1.50115 27.9129L1.8547 28.2665L4.73345 31.1452L5.087 31.4988L5.44056 31.1452L16.4999 20.0858L27.5593 31.1452L27.9128 31.4988L28.2664 31.1452L31.1451 28.2665L31.4987 27.9129L31.1451 27.5593L20.0858 16.5L31.1451 5.44062Z"
                    fill="#007B89"
                    stroke="#007B89"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};


export default Interstitial
