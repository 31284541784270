import React from 'react'
import Helmet from "react-helmet";

const HcpBBsSd =()=>{
	let dataOne = { "@context": "https://urldefense.com/v3/__http://www.schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-IwkRK3Q$  ",
		"@type": "MedicalWebPage",
		"description": "IMCIVREE is a prescription medicine used to treat certain types of obesity caused by genetic changes in the brain.",
		"sourceOrganization":{
			"@type": "Corporation",
			"name": "Rhythm Pharmaceuticals",
			"url": "https://urldefense.com/v3/__https://www.rhythmtx.com/__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-Re6XJxA$  ",
			"tickerSymbol": "RHTYM",
					"legalName": "Rhythm Pharmaceuticals, Inc.",
			"logo": {
							"@type": "ImageObject",
							"url": "https://urldefense.com/v3/__https://www.rhythmtx.com/wp-content/uploads/2020/11/Rhythm_Logo_CMYK_EPS-registration-mark-1.svg__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-zP3R6Fc$  "
						 },
			"telephone": "833-789-6337",
				 "address": {
					"@type": "PostalAddress",
					"addressCountry": "USA",
					"addressLocality": "Boston",
					"addressRegion": "Massachusetts",
					"postalCode": "02116",
					"streetAddress": "222 Berkeley Street, 12th Floor"
				},
						"sameAs": [
							"https://urldefense.com/v3/__https://www.linkedin.com/company/rhythm-pharmaceuticals-inc-/__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-ci7RpGg$  ",
							"https://urldefense.com/v3/__https://twitter.com/rhythmpharma__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-wx13mk4$  ",
							"https://urldefense.com/v3/__https://finance.yahoo.com/quote/RYTM__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-qOMuWRM$  ",
							"https://urldefense.com/v3/__https://www.bloomberg.com/profile/company/RYTM:US__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp--o-t3ZU$  ",
							"https://urldefense.com/v3/__https://www.reuters.com/markets/companies/RYTM.OQ/__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-_DYrDbk$  "
						 ],
						"description": "Rhythm is dedicated to understanding rare genetic diseases of obesity caused by impaired signaling in the central pathway of the brain known as the melanocortin-4 receptor (MC4R) pathway.",
			"Brand": {
				"@type": "Brand",
				"name": "IMCIVREE",
				"url": "https://urldefense.com/v3/__https://imcivree.com/__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-4r3q_oM$  ",
				"logo": "https://urldefense.com/v3/__https://imcivree.com/images/imcivree-logo.svg__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-ifrG-Dg$  "
			}
		}
	};

	let dataSix = {
		"@context": "https://urldefense.com/v3/__http://www.schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-IwkRK3Q$  ",
		"@type": "MedicalAudience",
		"name": "Healthcare Professional",
		"url": "https://urldefense.com/v3/__https://imcivree.com/__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-4r3q_oM$  ",
		"geographicArea":{
			"@type": "AdministrativeArea",
			"name": "This site is intended for US residents only.",
			"url": "https://urldefense.com/v3/__https://imcivree.com/hcp__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-ZLdzGrk$  "
		}
	}

	let dataTwo = {
		"@context": "https://urldefense.com/v3/__http://schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-m8FExX4$  ",
		"@type": "MedicalCondition",
		"name": "Bardet-Biedl Syndrome",
		"alternateName": "BBS",
		"description": "BBS is part of a class of disorders called ciliopathies (impairment of cilia function in cells)",
		"signOrSymptom": [
			"obesity",
			"hyperphagia (insatiable hunger)",
			"renal anomalies",
			"visual impairment",
			"cognitive impairment",
			"postaxial polydactyly",
			"hypogonadism",
			"speech delay",
			"developmental delay",
			"diabetes mellitus",
			"congenital heart disease",
			"dental anomalies",
			"brachydactyly/syndactyly",
			"ataxia/poor coordination",
			"anosmia/hyposmia"
		]
	}

	let dataThree = {
		"@context": "https://urldefense.com/v3/__http://www.schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-IwkRK3Q$  ",
		"@type": "Drug",
		"name": "IMCIVREE (setmelanotide)",
			"url": "https://urldefense.com/v3/__https://imcivree.com/hcp__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-ZLdzGrk$  ",
			"description": "IMCIVREE is indicated for chronic weight management in adult and pediatric patients 6 years of age and older with monogenic or syndromic obesity due to Bardet-Biedl syndrome (BBS).",
			"activeIngredient": "setmelanotide",
			"administrationRoute": "intravenous",
		"doseSchedule": {
					"@type": "DoseSchedule",
					"frequency": "IMCIVREE should be administered once daily, at the beginning of the day, without regard to meals"
					},
			"warning": "https://urldefense.com/v3/__https://rhythmtx.com/IMCIVREE/prescribing-information.pdf__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-6hG8KPg$  ",
			"medicineSystem": "evidence-based",
			"mechanismOfAction": "Setmelanotide is an MC4 receptor agonist with 20-fold less activity at the melanocortin 3 (MC3) and melanocortin 1 (MC1) receptors. MC4 receptors in the brain are involved in regulation of hunger, satiety, and energy expenditure.",
			"recognizingAuthority": "FDA",
			"relevantSpecialty": [
				"endocrinology",
				"genetics",
				"ophthalmology",
				"nephrology"
					],
			"prescribingInfo": "https://urldefense.com/v3/__https://rhythmtx.com/IMCIVREE/prescribing-information.pdf__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-6hG8KPg$  ",
			"prescriptionStatus": "Prescription-Only Medication",
			"image": {
				"@type": "ImageObject",
					"url":"https://urldefense.com/v3/__https://imcivree.com/images/imcivree-logo.svg__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-ifrG-Dg$  "
			}
	}

	let dataFour = {
		 "@context": "https://urldefense.com/v3/__http://schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-m8FExX4$  ",
		 "@type": "MedicalIndication",
		 "name": "IMCIVREE (setmelanotide)",
		 "description": "IMCIVREE is indicated for chronic weight management in adult and pediatric patients 6 years of age and older with monogenic or syndromic obesity due to Bardet-Biedl syndrome (BBS)."
	}

	let dataFive = {
		"@context": "https://urldefense.com/v3/__https://schema.org__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-pT-u95Y$  ",
		"@type": "FAQPage",
		"mainEntity": [{
			"@type": "Question",
			"name": "What is IMCIVREE indicated for?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "IMCIVREE is indicated for chronic weight management in adult and pediatric patients 6 years of age and older with monogenic or syndromic obesity due to Bardet-Biedl syndrome (BBS) &bull; IMCIVREE is not indicated for the treatment of patients with the following conditions as IMCIVREE would not be expected to be effective. Other types of obesity not related to BBS or other FDA-approved indications for IMCIVREE, including obesity associated with other genetic syndromes and general (polygenic) obesity. IMCIVREE is also indicated for chronic weight management in adult and pediatric patients 6 years of age and older with obesity due to pro-opiomelanocortin (POMC), proprotein convertase subtilisin/kexin type 1 (PCSK1), or leptin receptor (LEPR) deficiency as determined by an FDA-approved test demonstrating variants in POMC, PCSK1, or LEPR genes that are interpreted as pathogenic, likely pathogenic, or of uncertain significance (VUS). Please see the Prescribing Information for more information"
			}
		},{
			"@type": "Question",
			"name": "Do I need to confirm a diagnosis of BBS with genetic testing before treating with IMCIVREE?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "BBS is diagnosed clinically. Genetic testing can help provide additional diagnostic information and can confirm a clinical diagnosis in nearly 80% of patients. Consider the complete patient presentation and use your clinical judgment to diagnose BBS. Genetic testing is not required to prescribe IMCIVREE to patients with BBS. IMCIVREE is indicated for chronic weight management in adult and pediatric patients 6 years of age and older with monogenic or syndromic obesity due to BBS."
			}
		},{
			"@type": "Question",
			"name": "What is the recommended dose?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "In adult and pediatric patients 12 years of age and older, the recommended starting dose of IMCIVREE is 2 mg (0.2 mL) injected subcutaneously once daily for 2 weeks, and the recommended target dosage is 3 mg (0.3 mL) injected subcutaneously once daily. Monitor patients for gastrointestinal (GI) adverse reactions. If the starting dosage is: Not tolerated, reduce the dosage to 1 mg (0.1 mL) once daily. If the 1-mg once-daily dosage is tolerated for at least 1 week, increase the dosage to 2 mg (0.2 mL) once daily. Tolerated for 2 weeks, increase the dosage to 3 mg (0.3 mL) once daily. If the 3-mg once-daily dosage is not tolerated, decrease the dosage to 2 mg (0.2 mL) once daily. In pediatric patients aged 6 to less than 12 years, the recommended starting dose is 1 mg (0.1 mL) injected subcutaneously once daily for 2 weeks, and the recommended target dosage is 3 mg (0.3 mL) injected subcutaneously once daily. Monitor patients for GI adverse reactions. If the starting dosage is: Not tolerated, reduce the dosage to 0.5 mg (0.05 mL) once daily. If the 0.5-mg once-daily dosage is tolerated for at least 1 week, increase the dosage to 1 mg (0.1 mL) once daily. Tolerated for 2 weeks, increase the dosage to 2 mg (0.2 mL) once daily. If the 2-mg daily dosage is: Not tolerated, reduce the dosage to 1 mg (0.1 mL) once daily. Tolerated, increase the dosage to 3 mg (0.3 mL) once daily."
			}
		},{
			"@type": "Question",
			"name": "What is the recommended dose for patients with renal impairment?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "IMCIVREE is not recommended for use in patients with end stage renal disease (estimated glomerular filtration rate [eGFR] less than 15 mL/min/1.73 m2). For adults and pediatric patients 12 years of age and older with severe renal impairment (eGFR of 15 to 29 mL/min/1.73 m2), the recommended starting dosage is 0.5 mg (0.05 mL) injected subcutaneously once daily for 2 weeks, and the recommended target dosage is 1.5 mg (0.15 mL) injected subcutaneously once daily. Monitor patients for GI adverse reactions. If the recommended starting dose is tolerated for 2 weeks, increase the dosage to 1 mg (0.1 mL) once daily. If the 1-mg daily dosage is tolerated for at least 1 week, increase the dosage to 1.5 mg (0.15 mL) once daily. If the recommended starting dose is not tolerated, discontinue IMCIVREE. The use of IMCIVREE in pediatric patients 6 to less than 12 years of age with severe renal impairment is not recommended."
			}
		},{
			"@type": "Question",
			"name": "What were the most common adverse reactions/events reported in IMCIVREE in the BBS trial?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "The most common adverse reactions in IMCIVREE-treated patients were hyperpigmentation disorders, injection site reactions, and nausea. Adverse events (AEs) were generally mild and transient. No serious AEs related to IMCIVREE were reported in the BBS trial."
			}
		},{
			"@type": "Question",
			"name": "Were any serious treatment-related adverse reactions observed in clinical studies?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "No serious AEs related to IMCIVREE were reported in the BBS trial. IMCIVREE is not approved for use in neonates or infants. Serious and fatal adverse reactions including. “gasping syndrome” can occur in neonates and low birth weight infants treated with benzyl alcohol-preserved drugs."
			}
		},{
			"@type": "Question",
			"name": "Why can IMCIVREE cause hyperpigmentation?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "IMCIVREE is an MC4-receptor agonist with 20-fold less activity at the melanocortin-3 (MC3) and melanocortin-1 (MC1) receptors. The MC1 receptor is expressed on melanocytes, and activation of this receptor leads to accumulation of melanin and increased skin pigmentation independent of ultraviolet light."
			}
		},{
			"@type": "Question",
			"name": "Is skin darkening reversible?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Changes in skin pigmentation or hair color typically presented 2-3 weeks after initiation of IMCIVREE, with most events occurring within the first month of treatment. The effect was reversible after discontinuation of treatment."
			}
		},{
			"@type": "Question",
			"name": "Why does my patient need a skin examination before starting IMCIVREE?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Because IMCIVREE can cause hyperpigmentation, a full-body skin examination should be performed prior to initiation and periodically during treatment to monitor pre-existing and new skin pigmentary lesions."
			}
		},{
			"@type": "Question",
			"name": "Can IMCIVREE cause sexual adverse reactions?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Sexual adverse reactions may occur in patients treated with IMCIVREE. Spontaneous penile erections in males (24%) and sexual adverse reactions in females (7% in IMCIVREE-treated patients and 0% in placebo-treated patients from an unapproved population) occurred in clinical studies with IMCIVREE. Inform patients that these events may occur and instruct patients who have an erection lasting longer than 4 hours to seek emergency medical attention."
			}
		},{
			"@type": "Question",
			"name": "Did any patients discontinue treatment in the clinical trials due to ISRs?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "No patients taking IMCIVREE discontinued treatment in the clinical trials due to ISRs"
			}
		},{
			"@type": "Question",
			"name": "Will the nausea and vomiting caused by IMCIVREE go away/lessen over time?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Nausea or vomiting occurred in approximately 26% and 19% of patients, respectively, treated with IMCIVREE in the BBS clinical trial. Reported incidences of nausea and vomiting primarily occurred within the first month of treatment, then sharply declined after 4 weeks. Reported incidences of nausea and vomiting typically resolved within a few days in patients with a rare genetic disease of obesity in IMCIVREE clinical trials. To report SUSPECTED ADVERSE REACTIONS, contact Rhythm Pharmaceuticals at 1-833-789-6337 or FDA at 1-800-FDA-1088 or https://urldefense.com/v3/__http://www.fda.gov/medwatch__;!!N96JrnIq8IfO5w!hJGh1_IvaQLxqcFQsDBnfMlOzCmu1HgW4D1S1-fMZENkbmk0SzcXlfserOWq8bOAmQhCeUAVaQLfhiRccQuN2pBbQgp-E8Ttdqs$  "
			}
		},{
			"@type": "Question",
			"name": "How does IMCIVREE work (mechanism of action)?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Impairment in the MC4R pathway is a root cause of obesity and hyperphagia in BBS. IMCIVREE, an MC4R agonist, is designed to re-establish MC4R pathway activity. Re-establishing MC4R pathway activity may reduce food intake and promote weight loss through decreased caloric intake and increased energy expenditure. IMCIVREE is the first and only treatment to target impairment in the MC4R pathway."
			}
		},{
			"@type": "Question",
			"name": "How was IMCIVREE studied in patients with BBS?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "The efficacy and safety of IMCIVREE for the reduction of weight and hunger in patients with BBS were studied in a 1-year phase 3 clinical trial with a 14-week randomized, double-blind, placebo-controlled treatment period. The study enrolled patients aged 6 years and older with obesity and a clinical diagnosis of BBS."
			}
		},{
			"@type": "Question",
			"name": "How was the hunger score assessed?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Patients ≥12 years of age who were able to self-report their hunger (n=14) recorded their daily maximal hunger in a diary, which was then assessed by the Daily Hunger Questionnaire Item 2. Hunger was scored on an 11-point scale from 0 (“not hungry at all”) to 10 (“hungriest possible”)."
			}
		},{
			"@type": "Question",
			"name": "What were the efficacy results in the clinical study of IMCIVREE in patients with BBS?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "Weight reduction: ~8% mean reduction in BMI in patients ≥6 years of age after 1 year - Patients were not required to change their diet or exercise routine. Clinically significant -0.8 mean change in BMI Z-score in patients <18 years of age after 1 year - 100% of patients <12 years of age achieved a clinically significant ≥0.2 reduction in BMI Z-score (n=3). Clinically significant ~10% mean weight reduction in patients ≥18 years of age after 1 year; Hunger reduction: Statistically significant reduction in maximal hunger score."
			}
		},{
			"@type": "Question",
			"name": "What is a BMI Z-score?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "A BMI Z-score, also called a BMI standard deviation score, is a measure of relative weight adjusted for the child’s age and sex."
			}
		},{
			"@type": "Question",
			"name": "Is IMCIVREE commercially available?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "IMCIVREE is only available through our specialty pharmacy partner, PANTHERx. To prescribe IMCIVREE, a completed Start Form can be faxed to 1-877-805-0130 or emailed to patientsupport@rhythmtx.com"
			}
		},{
			"@type": "Question",
			"name": "How do I prescribe IMCIVREE for my patients?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "To prescribe IMCIVREE for a patient with BBS, a completed Start Form can be faxed to 1-877-805-0130 or emailed to patientsupport@rhythmtx.com"
			}
		},{
			"@type": "Question",
			"name": "Is there a copay program for IMCIVREE?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "The patient support program, Rhythm InTune, can answer any questions your patients may have about their insurance coverage for IMCIVREE. They can also facilitate the prior authorization process and register eligible, commercially insured patients with a qualifying diagnosis for a copay support program. Rhythm InTune can be reached at 1-855-206-0815, Monday through Friday, 8 AM to 8 PM ET."
			}
		},{
			"@type": "Question",
			"name": "Is there a patient support program for IMCIVREE?",
			"acceptedAnswer": {
				"@type": "Answer",
				"text": "The Rhythm InTune support program is designed for caregivers and people living with BBS. A Rhythm InTune Patient Education Manager is a single point of contact who can help patients and caregivers: Access educational resources, such as virtual education programs about BBS or treatment with IMCIVREE. Connect to a community where they can learn from the experiences of others. Access treatment by helping with understanding drug coverage, prior authorizations, appeals support, and, for eligible patients, copay support and financial assistance. Get started on treatment by coordinating IMCIVREE deliveries and injection support with the specialty pharmacy. Enrolling in Rhythm InTune is voluntary and allows your patient access to all the resources and support Rhythm Pharmaceuticals has to offer. To enroll a patient in Rhythm InTune, the patient must submit a completed and signed consent form via fax to 1-877-805-0130 or emailed to patientsupport@rhythmtx.com"
			}
		}]
	}

	return(
		<>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(dataOne)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataSix)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataTwo)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataThree)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataFour)}
				</script>
				<script type="application/ld+json">
					{JSON.stringify(dataFive)}
				</script>
			</Helmet>
		</>
	);
}

export default HcpBBsSd
