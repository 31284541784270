import React from "react"
import { Link } from "gatsby"

const IsiHcpPpl = () => {
  return (
    <div className="isi_two_column">
      <div className="isi_column_one">
        <p className="mb-0 md_mb-0 lg_mb-0">
          <strong className="h3">WHAT IS IMCIVREE?</strong>
        </p>
        <p>
          IMCIVREE is a prescription medicine used in adults and children 2
          years of age and older with obesity due to the genetic conditions
          pro-opiomelanocortin (POMC), proprotein convertase subtilisin/kexin
          type 1 (PCSK1), or leptin receptor (LEPR) deficiency, to help them
          lose weight and keep the weight off.
        </p>

        <p>
          Your healthcare provider should order an FDA-approved test to confirm
          POMC, PCSK1, or LEPR deficiency before you start using IMCIVREE.
        </p>

        <p className="mb-0 md_mb-0 lg_mb-0">
          IMCIVREE is not for use in people with the following conditions
          because it may not work:
        </p>
        <ul className="neon-green-bullets" style={{ marginBobbom: `.5rem` }}>
          <li>
            Obesity due to suspected POMC, PCSK1, or LEPR deficiency not
            confirmed by genetic testing or with benign or likely benign genetic
            testing results
          </li>
          <li>
            Other types of obesity not related to POMC, PCSK1, or LEPR
            deficiency, or other FDA-approved uses of IMCIVREE, including
            obesity associated with other genetic conditions and general obesity
          </li>
        </ul>
        <p>
          It is not known if IMCIVREE is safe and effective in children under 2
          years of age.
        </p>
      </div>

      <div className="isi_column_two">
        <p className="mb-0 md_mb-0 lg_mb-0">
          <div className="og small-12 color-teal lg_mb-0 mb-0">
            <strong className="h3">IMPORTANT SAFETY INFORMATION </strong>
          </div>

          <p>
            <strong>Do not use IMCIVREE if you</strong> have had a serious
            allergic reaction to it or any of its ingredients. Serious allergic
            reactions, including anaphylaxis, can happen.
          </p>

          <strong>
            Before you use IMCIVREE, tell your healthcare provider about all
            your medical conditions, including if you:
          </strong>
        </p>
        <ul className="neon-green-bullets" style={{ marginBottom: `.5rem` }}>
          <li>
            Have or have had areas of darkened skin, including skin
            discoloration (hyperpigmentation)
          </li>
          <li>Have or have had depression, or suicidal thoughts or behavior</li>
          <li>Have kidney problems</li>
          <li>
            Are pregnant or planning to become pregnant. Losing weight while
            pregnant may harm your unborn baby. Your healthcare provider may
            stop your treatment with IMCIVREE if you become pregnant. Tell your
            healthcare provider if you become pregnant or think you might be
            pregnant during treatment with IMCIVREE
          </li>
          <li>
            Are breastfeeding or plan to breastfeed. It is not known if IMCIVREE
            passes into your breast milk. You should not breastfeed during
            treatment with IMCIVREE
          </li>
        </ul>

        <p>
          <strong>
            Tell your healthcare provider about all the medicines you take,
          </strong>{" "}
          including prescription and over-the-counter medicines, vitamins, and
          herbal supplements.
        </p>

        <p>
          <strong>See the detailed Instructions for Use</strong> that come with
          your IMCIVREE to learn how to prepare and inject IMCIVREE, and how to
          properly throw away (dispose of) used syringes and needles.
        </p>

        <p className="mb-0 md_mb-0 lg_mb-0">
          <strong>
            What are the possible side effects of IMCIVREE? <br></br>IMCIVREE may cause
            serious side effects, including:
          </strong>
        </p>
        <ul className="neon-green-bullets mb-1 md_mb-1 lg_mb-1">
          <li>
            <strong>Male and female sexual function problems.</strong> IMCIVREE
            can cause an erection that happens without any sexual activity in
            males (spontaneous penile erection) and unwanted sexual reactions
            (changes in sexual arousal that happen without any sexual activity)
            in females. If you have an erection lasting longer than 4 hours, get
            emergency medical help right away
          </li>
          <li>
            <strong>Depression and suicidal thoughts or actions.</strong> You or
            a caregiver should call your healthcare provider right away if you
            have any new or worsening symptoms of depression, suicidal thoughts
            or behaviors, or any unusual changes in mood or behavior
          </li>
          <li>
            <strong>Serious allergic reactions.</strong> Stop taking IMCIVREE
            and get medical help right away if you have any symptoms of a
            serious allergic reaction including: swelling of your face, lips,
            tongue, or throat; problems breathing or swallowing; severe rash or
            itching; fainting or feeling dizzy; rapid heartbeat
          </li>
          <li>
            <strong>
              Increased skin pigmentation, darkening of skin lesions (moles or
              nevi) you already have, and development of new skin lesions.{" "}
            </strong>{" "}
            These changes happen because of how IMCIVREE works in the body and
            will go away when you stop using IMCIVREE. You should have a full
            body skin exam before starting and during treatment with IMCIVREE to
            check for skin changes
          </li>
          <li>
            <strong>Benzyl alcohol toxicity.</strong> Benzyl alcohol is a
            preservative in IMCIVREE. Benzyl alcohol can cause serious side
            effects, including death, in premature and low-birth weight infants
            who have received medicines that contain benzyl alcohol. IMCIVREE
            should not be used in premature and low-birth weight infants
          </li>
        </ul>

        <p>
          <strong>The most common side effects of IMCIVREE include</strong>{" "}
          darkening of the skin, injection site reactions, nausea, headache,
          diarrhea, stomach pain, vomiting, depression, and an erection that
          happens without any sexual activity in males.
        </p>

        <div>
          <p>
            These are not all the possible side effects of IMCIVREE. Call your
            doctor for medical advice about side effects. You are encouraged to
            report negative side effects of prescription drugs to FDA. Visit{" "}
            <a
              className="underline"
              href="https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program"
            >
              www.fda.gov/medwatch
            </a>{" "}
            or call FDA at 1-800-FDA-1088.
          </p>
        </div>

        <p>
          <strong>
            Please see full prescribing information, including{" "}
            <a
              className="underline"
              rel="noopener noreferrer"
              href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPatientPrescribingInformation.pdf"
              target="_blank"
            >
              Patient Information
            </a>
            .{" "}
          </strong>
        </p>
      </div>
    </div>
  )
}

export default IsiHcpPpl
