import React from "react"
import { Link } from "gatsby"

const IsiHcpPpl = ({ ppl }) => {
  return (
    <div className="isi_two_column">
      <div className="isi_column_one">
        <p className="mb-0 md_mb-0 lg_mb-0">
          <strong className="h3">Indication</strong>
        </p>
        <p>
          IMCIVREE is indicated to reduce excess body weight and maintain weight reduction long term in adults and pediatric patients aged 2 years and older with syndromic or monogenic obesity due to pro-opiomelanocortin (POMC), proprotein convertase subtilisin/kexin type 1 (PCSK1), or leptin receptor (LEPR) deficiency as determined by an FDA-approved test demonstrating variants in POMC, PCSK1, or LEPR genes that are interpreted as pathogenic, likely pathogenic, or of uncertain significance (VUS). 
        </p>

        <p className="mb-0 md_mb-0 lg_mb-0">
          <strong>Limitations of Use</strong>
        </p>
        <p className="mb-0 md_mb-0 lg_mb-0">
          IMCIVREE is <u>not</u> indicated for the treatment of patients with
          the following conditions as IMCIVREE would not be expected to be
          effective:
        </p>
        <ul className="neon-green-bullets" style={{ marginBottom: `.5rem` }}>
          <li>
            Obesity due to suspected POMC, PCSK1, or LEPR deficiency with{" "}
            POMC, PCSK1, or LEPR variants classified as benign or likely
            benign
          </li>
          <li>
            Other types of obesity not related to POMC, PCSK1, or LEPR
            deficiency, or other FDA- approved indications for IMCIVREE,
            including obesity associated with other genetic syndromes and
            general (polygenic) obesity
          </li>
        </ul>
      </div>

      <div className="isi_column_two">
        <div className=" og small-12 color-teal lg_mb-0 mb-0 important_safety">
          <strong className="h3">Important Safety Information</strong>
        </div>
        <p className="mb-0 md_mb-0 lg_mb-0">
          <strong class="normal">CONTRAINDICATIONS</strong>
        </p>
        <p>
          Prior serious hypersensitivity to setmelanotide or any of the
          excipients in IMCIVREE. Serious hypersensitivity reactions (e.g.,
          anaphylaxis) have been reported.
        </p>
        <p className="mb-1 md_mb-1 lg_mb-1">
          <strong>WARNINGS AND PRECAUTIONS</strong>
        </p>
        <p className="mb-1 md_mb-1 lg_mb-1">
          <strong>Disturbance in Sexual Arousal:</strong> Spontaneous penile
          erections in males and sexual adverse reactions in females have
          occurred. Inform patients that these events may occur and instruct
          patients who have an erection lasting longer than 4 hours to seek
          emergency medical attention.
        </p>
        <p className="mb-1 md_mb-1 lg_mb-1">
          <strong>Depression and Suicidal Ideation:</strong> Depression, 
          suicidal ideation, and depressed mood have occurred. Monitor patients for new onset or
          worsening depression or suicidal thoughts or behaviors. Consider
          discontinuing IMCIVREE if patients experience suicidal thoughts or
          behaviors, or clinically significant or persistent depression symptoms
          occur.
        </p>
        <p className="mb-1 md_mb-1 lg_mb-1">
          <strong>Hypersensitivity Reactions:</strong> Serious hypersensitivity
          reactions (e.g., anaphylaxis) have been reported. If suspected, advise
          patients to promptly seek medical attention and discontinue IMCIVREE.
        </p>
        <p className="mb-1 md_mb-1 lg_mb-1">
          <strong>Skin Hyperpigmentation, Darkening of Pre-existing Nevi, and Development of New Melanocytic Nevi:</strong>{" "}
          Generalized or focal increased skin pigmentation, darkening of pre-existing nevi, development of new melanocytic nevi and increase in size of existing melanocytic nevi have occurred. Perform a full body skin examination prior to initiation and periodically during treatment to monitor pre-existing and new pigmented lesions. 
        </p>
        <p className="mb-1 md_mb-1 lg_mb-1">
          <strong>
            Risk of Serious Adverse Reactions Due to Benzyl Alcohol Preservative
            in Neonates and Low Birth Weight Infants:
          </strong>{" "}
          IMCIVREE is not approved for use in neonates or infants. Serious and
          fatal adverse reactions including “gasping syndrome” can occur in
          neonates and low birth weight infants treated with benzyl
          alcohol-preserved drugs.
        </p>
        <p className="mb-1 md_mb-1 lg_mb-1">
          <strong>ADVERSE REACTIONS</strong>
        </p>
        <ul className="neon-green-bullets" style={{ marginBottom: `.5rem` }}>
          <li>
            Most common adverse reactions (incidence ≥20%) included skin
            hyperpigmentation, injection site reactions, nausea, headache,
            diarrhea, abdominal pain, vomiting, depression, and spontaneous
            penile erection
          </li>
        </ul>
        <p className="mb-1 md_mb-1 lg_mb-1">
          <strong>USE IN SPECIFIC POPULATIONS</strong>
        </p>
        <p className="mb-1 md_mb-1 lg_mb-1">
          Treatment with IMCIVREE is not recommended when breastfeeding.
          Discontinue IMCIVREE when pregnancy is recognized unless the benefits
          of therapy outweigh the potential risks to the fetus.
        </p>

        <p>
        To report SUSPECTED ADVERSE REACTIONS, contact Rhythm Pharmaceuticals at 833-789-6337 or FDA at 1-800-FDA-1088 or{" "}
          <a className="underline" href="https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program">www.fda.gov/medwatch</a>.
        </p>

        <p>
          <strong>
            Please see full{" "}
            <a
              className="underline"
              rel="noopener noreferrer"
              href="https://rhythmtx.com/IMCIVREE/prescribing-information.pdf"
              target="_blank"
            >
              Prescribing Information
            </a>{" "}
            for additional Important Safety Information.
          </strong>
        </p>
      </div>
    </div>
  )
}

export default IsiHcpPpl
